import * as Jed from 'jed';
import {getLang} from './locale';
import * as messages from './messages';

const lang = getLang();

const defaultMessages = {
    "":{
        "domain": "messages",
        "lang": lang,
        "plural_forms":"nplurals=2; plural=(n != 1)"
    },
};

let jed = new Jed({
    locale_data : {
        messages: messages[lang] ? messages[lang] : defaultMessages
    },
    domain : 'messages',
    language: lang,
});

export function _(key, keyPlural, num) {
    if (arguments.length === 3) {
        return jed.translate(key).ifPlural(num, keyPlural).fetch(num);
    } else {
        return jed.gettext(key);
    }
};
