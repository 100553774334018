export function convertChar(char) {
    const langs = [
        ['é', '+', 'ě', 'š', 'č', 'ř', 'ž', 'ý', 'á', 'í', '='], // cs
        ['é', '+', 'ľ', 'š', 'č', 'ť', 'ž', 'ý', 'á', 'í', '='], // sk
        ['ö', '1', '2', '3', '4', '5', '6', '7', '8', '9', '='], // hu
    ];

    const to = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, '-'];

    langs.forEach((lang) => {
        lang.forEach((letter, key) => {
            if(letter.toLowerCase() === char) {
                char = to[key];
            }
        });
    });

    return char;
}


export function toInt(value) {
    value = parseInt(value);
    if(isNaN(value)) {
        value = 0;
    }
    return value;
}


export function toFloat(value) {
    value = parseFloat(value);
    if(isNaN(value)) {
        value = 0;
    }
    return value;
}