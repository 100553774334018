import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from '../redux/bindActionCreators';
import * as flashMessageActions from '../redux/modules/flashMessage';
import {_} from '../locales/gettext';
import * as C from '../utils/constants';
import ComponentHelpers from '../utils/componentHelpers';
import classNames from "classnames";
import FaIcon from "./faIcon";
import Documents from "./documents";
import FlashMessage from "./flashMessage";
import Tooltip from "react-tooltip-lite";

export default connect(
    (state, props) => {
        return {
        }
    },
    (dispatch) => {
        return {
            flashMessageActions: bindActionCreators(flashMessageActions, dispatch),
        }
    }
)(
    class QualityAssuranceItem extends React.Component {

        static propTypes = {
            data: PropTypes.object.isRequired,
            defects: PropTypes.array.isRequired,
            onAddDefect: PropTypes.func.isRequired,
            statesWithoutDocuments: PropTypes.array.isRequired,
        };


        static defaultProps = {
            statesWithoutDocuments: [],
        };

        constructor(props) {
            super(props);

            this.state = {
                defect: null,
            };

            this.componentHelpers = new ComponentHelpers(this, null);
        }


        componentDidUpdate(prevProps, prevState, snapshot) {
        }


        getDefect(id) {
            let result = null;
            this.props.defects.forEach((defect) => {
                if(defect.id == id) {
                    result = defect;
                }
            });
            return result;
        }


        handleAddCheck(defect, type) {
            this.setState({
                defect: {
                    id: defect,
                    state: type,
                    description: '',
                    documents: []
                },
            }, () => {
                if(this.props.statesWithoutDocuments.indexOf(type) >= 0) {
                    this.handleDefect(this.state.defect);
                };
            });
        }


        handleAddDocument(document) {
            const defect = this.state.defect;
            defect.documents.unshift({
                type: document.type,
                base64: document.base64,
            });
            this.setState({
               defect: defect,
            });
        }


        handleRemoveDocument(index) {
            const defect = this.state.defect;
            defect.documents.splice(index, 1);
            this.setState({
               defect: defect,
            });
        }


        handleDefect(data) {
            this.props.onAddDefect(this.props.data.serial_number, data);
            this.setState({defect: null});
            this.props.flashMessageActions.set(<FlashMessage
                fullScreen={false}
                type={C.flashMessages.INFO}
                closeButton={true}
                autoHide={true}
            >
                {_('Result added')}
            </FlashMessage>);
        }


        handleBack() {
            if(this.state.defect.description || this.state.defect.documents.length) {
                if(window.confirm(_('Your unsaved changes will be lost, are you sure?'))) {
                    this.setState({
                        defect: null,
                    });
                }
            } else {
                this.setState({
                   defect: null,
                });
            }
        }


        render() {
            let content = [];

            if(this.state.defect) {
                const defect = this.state.defect;
                const defectDetails = this.getDefect(this.state.defect.id);
                content.push(<div key="list" style={{marginBottom: '200px'}}>
                    <h1>
                        {defectDetails.title}: <span className={classNames('qaItemStatus', {[defect.state]: true})}>{this.componentHelpers.getDefectStateTitle(defect.state)}</span>
                    </h1>
                    <div className="" style={{marginRight: '1em', marginBottom: '20px'}}>
                        <button key="back" onClick={() => this.handleDefect(defect)} className="smallButton">
                            <FaIcon icon={'plus'} /> {_('Add Result')}
                        </button>
                        <button key="send" onClick={() => this.handleBack()} className="smallButton" style={{marginLeft: '10px'}}>
                            <FaIcon icon={'undo'} /> {_('Go Back')}
                        </button>
                    </div>
                    <div style={{marginBottom: '1em'}}>
                        <Documents
                            documents={defect.documents}
                            onAdd={(document) => this.handleAddDocument(document)}
                            onRemove={(index) => this.handleRemoveDocument(index)}
                            allowAdd={[C.documentType.IMAGE]}
                            allowRemove={true}
                            webcamOpen={true}
                        />
                    </div>
                    <div style={{marginTop: '2em'}}>
                        <textarea
                          placeholder={_('Description')}
                            ref="notice"
                            defaultValue={defect.description}
                            onChange={(e) => {
                                defect.description = e.target.value;
                                this.setState({defect: defect});
                            }}
                        />
                    </div>
                </div>);
            } else {
                let rows = [];
                let marked = false;
                const concerns = this.props.data.result && this.props.data.result.concerns ? this.props.data.result.concerns : [];
                let currentInspectionResults = concerns;
                if(this.props.data.inspections) {
                    this.props.data.inspections.forEach((inspection) => {
                        if(inspection.new && inspection.results) {
                            currentInspectionResults = inspection.results;
                        }
                    })
                }
                this.props.defects.forEach((defect) => {
                    let defectState = null;
                    concerns.forEach((defect2) => {
                        if(defect2.defect == defect.id) {
                            defectState = defect2.state;
                        }
                    });

                    let currentDefectState = null;
                    currentInspectionResults.forEach((defect2) => {
                        if(defect2.id == defect.id) {
                            currentDefectState = defect2.state;
                        }
                    });

                    const current = !currentDefectState && !marked
                    rows.push(<tr key={defect.id}>
                        <td>
                            {
                              current &&
                                <span style={{marginRight: '0.2em', color: 'red'}}>
                                    <FaIcon icon={'arrow-right'} />
                                </span>
                            }
                            {defect.title}
                        </td>
                        <td>
                            <b className={classNames('qaItemStatus', {[defectState]: true})}>{this.componentHelpers.getDefectStateTitle(defectState)}</b>
                        </td>
                        <td>
                            <button
                                className={classNames('badge', {smallButton: true, disabled: !current, pointer: true, [C.qaDefectStatus.ABSENT]: true})}
                                onClick={() => this.handleAddCheck(defect.id, C.qaDefectStatus.ABSENT)}
                                style={{marginRight: '1em'}}
                            >
                                {this.componentHelpers.getDefectStateTitle(C.qaDefectStatus.ABSENT)}
                            </button>
                            <button
                                className={classNames('badge', {smallButton: true, disabled: !current, pointer: true, [C.qaDefectStatus.PRESENT]: true})}
                                onClick={() => this.handleAddCheck(defect.id, C.qaDefectStatus.PRESENT)}
                                style={{marginRight: '1em'}}
                            >
                                {this.componentHelpers.getDefectStateTitle(C.qaDefectStatus.PRESENT)}
                            </button>
                            {
                                !!defect.reworkable &&
                                <button
                                    className={classNames('badge', {smallButton: true, disabled: false, pointer: true, [C.qaDefectStatus.REPAIRED]: true})}
                                    onClick={() => this.handleAddCheck(defect.id, C.qaDefectStatus.REPAIRED)}
                                >
                                    {this.componentHelpers.getDefectStateTitle(C.qaDefectStatus.REPAIRED)}
                                </button>
                            }
                            {
                              /*
                                !defect.reworkable &&
                                <button
                                    className={classNames({smallButton: true, disabled: true, pointer: false})}
                                >
                                    <Tooltip content={_('Item is not reworkable')}>
                                        {this.componentHelpers.getDefectStateTitle(C.qaDefectStatus.REPAIRED)}
                                    </Tooltip>
                                </button>
                              */
                            }
                        </td>
                    </tr>);
                    if(!currentDefectState) {
                        marked = true;
                    }
                });

                content.push(<table key="table" className="defectsTable" border="1" cellSpacing="0">
                    <tbody>
                        <tr className="headRow">
                            <th>{_('Defect')}</th>
                            <th>{_('State')}</th>
                            <th>{_('Add Check')}</th>
                        </tr>
                        {rows}
                    </tbody>
                </table>);

                // TODO tu by to chcelo zobrazit tlacitko "Finish", pokial su vsetky defekty skontrolovane, ale vyzadovalo by to refaktor, pretoze ta akcia je definovana v `currentQualityAssurance.js`

            }


            return <div className="qaItem">
                {content}
            </div>;
        }
    }
)
