import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Reader from './qrReader/index'
import Quagga from 'quagga'
import Tooltip from 'react-tooltip-lite'
import FaIcon from './faIcon'
import {_} from '../locales/gettext'
import BarcodeScannerComponent from 'react-qr-barcode-scanner'

export default class CodeReader extends React.Component {
  static propTypes = {
    onScan: PropTypes.func.isRequired,
    tooltip: PropTypes.string,
    defaultType: PropTypes.string,
    allowBarCode: PropTypes.bool,
  }

  static defaultProps = {
    tooltip: _('Record Code'),
    defaultType: 'BAR',
    allowBarCode: true,
  }

  constructor(props) {
    super(props)

    this.initState = {
      error: null,
      openScan: false,
      canScan: false,
      type: props.defaultType,
    }

    this.state = this.initState
  }

  componentDidMount() {}

  componentWillUnmount() {
    this.showElements()
  }

  hideElements() {
    ;[].forEach.call(document.querySelectorAll('.flashMessage'), function (el) {
      el.style.backgroundColor = 'transparent'
    })
    ;[].forEach.call(document.querySelectorAll('.scanHide'), function (el) {
      el.style.visibility = 'hidden'
    })
  }

  showElements() {
    ;[].forEach.call(document.querySelectorAll('.flashMessage'), function (el) {
      el.style.backgroundColor = ''
    })
    ;[].forEach.call(document.querySelectorAll('.scanHide'), function (el) {
      el.style.visibility = 'visible'
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.openScan) {
      this.hideElements()
    } else {
      this.showElements()
    }
  }

  handleScan(data) {
    if (data) {
      this.setState(this.initState)
      this.props.onScan(data)
    }
  }

  handleError(error) {
    let message = _('Scan error, please try again')
    if (error && error.message && error.message == 'Permission denied') {
      message = _('Scan error, please allow camera in your browser')
    }
    this.setState({
      canScan: false,
      error: message,
    })
  }

  changeType(type) {
    this.setState({
      canScan: false,
      type: type,
    })
  }

  openScan(open) {
    this.setState({
      canScan: false,
      openScan: open,
    })
  }

  canScan() {
    this.setState({
      canScan: true,
    })
  }

  render() {
    if (this.state.openScan) {
      return (
        <div id='codeReader'>
          <div
            className='inner'
            style={{backgroundColor: 'white'}}>
            <div className='close'>
              <div
                onClick={() => {
                  this.openScan(false)
                }}>
                <FaIcon icon='times' />
              </div>
            </div>
            <div className='scanner'>
              {!this.state.canScan && (
                <div
                  className='overlay'
                  onClick={() => this.canScan()}>
                  <span className='icon'>
                    <FaIcon icon='camera' />
                  </span>
                </div>
              )}

              <div style={{minHeight: '300px'}}>
                {this.state.canScan && (
                  <BarcodeScannerComponent
                    delay={500}
                    onUpdate={(err, result) => {
                      if (result) this.handleScan(result.text)
                    }}
                    onError={(err) => {
                      this.handleError(err)
                    }}
                  />
                )}
              </div>
              <div
                id='quaggaReactContainer'
                className={classNames({hidden: this.state.type === 'QR'})}
                style={{
                  position: 'absolute',
                  top: 0,
                  height: '100%',
                  width: '100%',
                }}>
                <div className='topOverlay'></div>
                <div className='bottomOverlay'></div>
                <div className='leftOverlay'></div>
                <div className='rightOverlay'></div>
              </div>
            </div>

            <div className='line'>
              <div>{this.state.error}</div>
              <b>
                {_('Scan')}
                &nbsp;{this.state.type}
                &nbsp;{_('Code')}
              </b>

              <div className='icons'>
                <Tooltip
                  content='QR code'
                  className={classNames({target: true, hidden: this.state.type === 'QR'})}>
                  <span
                    className='anchor iconAnchor codeSwitch'
                    onClick={() => this.changeType('QR')}>
                    <FaIcon icon='qrcode' />
                  </span>
                </Tooltip>

                {this.props.allowBarCode && (
                  <Tooltip
                    content='BAR code'
                    className={classNames({target: true, hidden: this.state.type === 'BAR'})}>
                    <span
                      className='anchor iconAnchor codeSwitch'
                      onClick={() => this.changeType('BAR')}>
                      <FaIcon icon='barcode' />
                    </span>
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <Tooltip
          content={this.props.tooltip}
          className='target scanIcon scanHide'>
          <span
            className='anchor iconAnchor'
            onClick={() => this.openScan(true)}>
            <FaIcon icon='camera' />
          </span>
        </Tooltip>
      )
    }
  }
}
