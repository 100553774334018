import React from 'react'
import Page from './page'
import {connect} from 'react-redux'
import Tooltip from 'react-tooltip-lite'
import {Link} from 'react-router-dom'
import * as C from '../utils/constants'
import {_} from '../locales/gettext'
import {sprintf} from 'sprintf-js'
import {bindActionCreators} from '../redux/bindActionCreators'
import * as flashMessageActions from '../redux/modules/flashMessage'

export default connect(
  (state, props) => {
    return {
      flashMessage: state.flashMessage,
      work: state.work,
      qualityAssurance: state.qualityAssurance,
      unfinishedWorksheets: state.unfinishedWorksheets,
    }
  },
  (dispatch) => {
    return {
      flashMessageActions: bindActionCreators(flashMessageActions, dispatch),
    }
  }
)(
  class HomePage extends Page {
    getContent(state) {
      const unfinishedWorks = this.props.unfinishedWorksheets
        ? this.props.unfinishedWorksheets.length
        : 0

      let workButton = (
        <Link to={C.routes.WORK}>
          <button value=''>{_('Start Sorting Job')}</button>
        </Link>
      )

      let qaButton = (
        <Link to={C.routes.QUALITY_ASSURANCE}>
          <button value=''>{_('Start EOL Job')}</button>
        </Link>
      )

      const auditButton = (
        <button
          value=''
          className='disabled'>
          {_('Start Audit')}
        </button>
      )

      if (this.props.work || unfinishedWorks) {
        qaButton = (
          <Tooltip content={_('Resolve unfinished works first')}>
            <button
              value=''
              className='disabled'>
              {_('Start EOL Job')}
            </button>
          </Tooltip>
        )

        if (this.props.work) {
          workButton = (
            <Link to={C.routes.WORK}>
              <button value=''>{_('Resume Work')}</button>
            </Link>
          )
        } else {
          workButton = (
            <Link to={C.routes.UNFINISHED}>
              <button value=''>
                {sprintf(_('Resume Unfinished Works (%s)'), unfinishedWorks)}
              </button>
            </Link>
          )
        }
      } else if (this.props.qualityAssurance) {
        qaButton = (
          <Link to={C.routes.QUALITY_ASSURANCE}>
            <button value=''>{_('Resume EOL Job')}</button>
          </Link>
        )

        workButton = (
          <Tooltip content={_('Resolve unfinished EOL job first')}>
            <button
              value=''
              className='disabled'>
              {_('Start Work')}
            </button>
          </Tooltip>
        )
      }

      return (
        <div className='center'>
          <h2>{_('Home Page')}</h2>
          <div style={{marginBottom: '1.5em'}}>{workButton}</div>
          <div style={{marginBottom: '1.5em'}}>{qaButton}</div>
          <div style={{marginBottom: '1.5em'}}>{auditButton}</div>
          {
            /*[C.roles.SUPERADMIN, C.roles.ADMIN, C.roles.TEAMLEADER].indexOf(getRole()) >= 0*/ true && (
              <div>
                <Link to={C.routes.TEST_SCAN}>
                  <button
                    value=''
                    className='subdued'>
                    {_('Test Scan')}
                  </button>
                </Link>
              </div>
            )
          }
        </div>
      )
    }
  }
)
