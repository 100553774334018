import * as C from '../utils/constants';

const storage = localStorage;

export function setLang(lang) {
    storage.setItem('lang', lang);
}

export function getLang() {
    let lang = storage.getItem(('lang'));
    if(C.languages.indexOf(lang) < 0) {
        lang = C.defaultLang;
        setLang(lang);
    }

    return lang;
}