import React from 'react'
import PropTypes from 'prop-types'
import {_} from '../locales/gettext'

export default class Form extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    title: PropTypes.string,
    text: PropTypes.string,
    submitText: PropTypes.string,
    alert: PropTypes.any,
  }

  static defaultProps = {
    submitText: _('Submit'),
  }

  constructor(props) {
    super(props)

    this.state = {
      submitCheck: false,
    }

    this.sending = false
  }

  onFormSubmit(e) {
    e.preventDefault()
    this.submit()
  }

  submit() {
    if (this.sending) {
      return
    }
    this.sending = true
    this.setState({
      submitCheck: true,
    })

    let valid = true
    let values = {}
    let value

    for (let key in this.refs) {
      if (!this.refs[key].isValid()) {
        valid = false
      }

      value = this.refs[key].getValue()

      if (typeof value === 'string') {
        value = value.trim()
      }

      values[key] = value
    }

    if (valid) {
      this.props.onSubmit(values)
    }

    setTimeout(() => {
      this.sending = false
    }, 100)
  }

  getForm(inputs) {
    return (
      <form onSubmit={(e) => this.onFormSubmit(e)}>
        {!!this.props.title && <h2>{this.props.title}</h2>}
        {!!this.props.text && <p>{this.props.text}</p>}
        <div className='formInput'>
          {inputs}
          {this.props.alert}
          <button onClick={() => this.submit()}>{this.props.submitText}</button>
        </div>
      </form>
    )
  }

  render() {
    return this.getForm(this.props.children)
  }
}
