import React from 'react';
import {connect} from 'react-redux';
import Page from './page';
import Unfinished from "../components/unfinished";
import {bindActionCreators} from "../redux/bindActionCreators";
import * as flashMessageActions from "../redux/modules/flashMessage";
import {Redirect} from "react-router-dom";
import * as C from "../utils/constants";


export default connect(
    (state, props) => {
        return {
            flashMessage: state.flashMessage,
            unfinishedWorksheets: state.unfinishedWorksheets,
        }
    },
    (dispatch) => {
        return {
            flashMessageActions: bindActionCreators(flashMessageActions, dispatch),
        }
    }
)(
    class UnifinshedPage extends Page {

        constructor(props) {
            super(props);

            this.state = {
                selected: null
            };
        }

        handleSelect(item) {
            this.setState({selected: item});
        }


        getContent(state) {

            if(!this.props.unfinishedWorksheets || this.props.unfinishedWorksheets.length == 0) {
                return <Redirect to={C.routes.HOMEPAGE} />;
            }

            if(this.state.selected) {
                return <Redirect
                    to={{
                        pathname: C.routes.WORK,
                        state: {
                            unfinished: this.state.selected,
                        },
                    }}
                />;
            }

            return <div className="center">
                <Unfinished
                    onSelect={(item) => {
                        this.handleSelect(item);
                    }}
                />
            </div>;
        }

    }
)
