import React from 'react';
import Tooltip from 'react-tooltip-lite';
import {_} from '../locales/gettext';
import * as C from '../utils/constants';
import * as locale from '../locales/locale';
import FaIcon from './faIcon';
import FontSize from '../components/fontSize';

export default class LangSelect extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            open: false,
        };
    }


    handleOpen() {
        const open = this.state.open;
        this.setState({open: !open});
    }


    handleSelect(lang) {
        if(locale.getLang() !== lang) {
            locale.setLang(lang);
            window.location.reload();
        }
    }


    render() {
        const selected = locale.getLang();
        let elements = [];

        if(this.state.open) {
            C.languages.forEach((item) => {
                if(item !== selected) {
                    elements.push(
                        <span
                            onClick={() => this.handleSelect(item)}
                            key={item}
                        >
                            {item}
                        </span>
                    );
                }
            });
        }

        elements.push(
            <span
                onClick={() => this.handleOpen()}
                key="icon"
            >
                <Tooltip content={_('Select language')}>
                    <FaIcon icon={'globe-europe'} /> <b>{selected}</b>
                </Tooltip>
            </span>
        );

        return <div id="langSelect">
            {elements}
            <FontSize />
        </div>;
    }

}
