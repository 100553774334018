function bindActionCreator(actionCreator, dispatch) {
	return (...args) => dispatch(actionCreator(...args))
}

export function bindActionCreators(actionCreators, dispatch) {
	if (typeof actionCreators === 'function') {
		return bindActionCreator(actionCreators, dispatch)
	}
	
	if (typeof actionCreators !== 'object' || actionCreators === null) {
		throw new Error(
		  `bindActionCreators expected an object or a function, instead received ${actionCreators === null ? 'null' : typeof actionCreators}. ` +
		  `Did you write "import ActionCreators from" instead of "import * as ActionCreators from"?`
		)
	}
	
	var keys = Object.keys(actionCreators)
	var boundActionCreators = {}
	for (let i = 0; i < keys.length; i++) {
		var key = keys[i]
		var actionCreator = actionCreators[key]
		if (typeof actionCreator === 'function') {
			boundActionCreators[key] = bindActionCreator(actionCreator, dispatch)
		}
	}
	return boundActionCreators
}
