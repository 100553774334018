import React from 'react';
import PropTypes from 'prop-types';
import {Redirect} from 'react-router-dom';
import FaIcon from './faIcon';
import Tooltip from 'react-tooltip-lite';
import {connect} from 'react-redux';
import {bindActionCreators} from '../redux/bindActionCreators';
import * as workActions from '../redux/modules/work';
import * as worksheetActions from '../redux/modules/worksheet';
import * as batchActions from '../redux/modules/batch';
import * as flashMessageActions from '../redux/modules/flashMessage';
import * as unfinishedWorksheetsActions from '../redux/modules/unfinishedWorksheets';
import FlashMessage from './flashMessage';
import * as C from '../utils/constants';
import {_} from '../locales/gettext';
import ApiClient from '../utils/apiClient';
import Highlights from './highlights';
import Notice from './notice';
import Stats from './stats';
import BatchCounts from './batchCounts';
import ErrorMessage from './errorMessages';
import classNames from "classnames";

export default connect(
    (state, props) => {
        return {
            work: state.work,
            worksheet: state.worksheet,
            batch: state.batch,
            qualityAssurance: state.qualityAssurance,
        }
    },
    (dispatch) => {
        return {
            worksheetActions: bindActionCreators(worksheetActions, dispatch),
            unfinishedWorksheetsActions: bindActionCreators(unfinishedWorksheetsActions, dispatch),
            batchActions: bindActionCreators(batchActions, dispatch),
            workActions: bindActionCreators(workActions, dispatch),
            flashMessageActions: bindActionCreators(flashMessageActions, dispatch),
        }
    }
)(
    class CurrentWork extends React.Component {

        static propTypes = {
            onFinish: PropTypes.func.isRequired,
        };


        constructor(props) {
            super(props);

            this.state = {
            };

            this.api = new ApiClient(this.props);
        }


        async finishJob(save) {
            if(window.confirm(_('Finish job, are you sure?'))) {
                try {
                    if(this.props.worksheet) {
                        this.props.unfinishedWorksheetsActions.remove(this.props.worksheet.id);
                        await this.api.patch(C.workGroup + '/worksheets/' + this.props.worksheet.id + '/committed', {committed: true, comment: this.props.worksheet.comment});
                        this.props.flashMessageActions.set(<FlashMessage fullScreen={false} closeButton={true}>{_('Worksheet commited')}</FlashMessage>);
                    }
                    this.props.workActions.clear();
                    this.props.worksheetActions.clear();
                    this.props.batchActions.clear();
                    this.props.onFinish();
                } catch(error) {
                    this.props.flashMessageActions.set(<ErrorMessage fullScreen={false} errorCode={error.code} errorMessage={error.message} />);
                }
            }
        }


        finishBatch() {
            if(window.confirm(_('Finish batch, are you sure?'))) {
                this.props.workActions.addBatch(this.props.batch);
                this.props.batchActions.clear();
                this.api.post(C.workGroup + '/worksheets/' + this.props.worksheet.id + '/boxes/' + this.props.batch.id + '/transactions/close', {});
            }
        }


        handleEdit() {
            this.props.flashMessageActions.set(<BatchCounts edit={true} />);
            /*
            this.props.flashMessageActions.set(<ErrorMessage
                errorMessage={_('Edit batch data, inform TeamLeader')}
                errorCode={C.errors.CHECK_TL}
                onSubmit={() => this.props.flashMessageActions.set(<BatchCounts edit={true} />)}
                onInvalidSubmit={(error) => this.props.flashMessageActions.set(<ErrorMessage
                    errorMessage={error.message}
                    errorCode={C.errors.BAD_LOGIN}
                    onInvalidSubmit={() => {}}
                />)}
            />);
            */
        }


        calculateCycleTime(cycleTime) {
            return cycleTime > 200 ? Math.round(3600 / cycleTime * 100) / 100 : Math.round(3600 / cycleTime);
        }


        render() {
            if(!this.props.work || this.props.qualityAssurance) {
                return null;
            } else {
                let content = [];
                const jobsCount = this.props.work.jobs.length;
                const multiJob = jobsCount > 1;

                if(this.props.work && this.props.worksheet) {
                    this.props.work.jobs.forEach((job, key) => {

                        let cycleTime = 0;
                        if(this.props.work.jobs.length > 1 && job.refs) {
                            job.refs.forEach((ref) => {
                                if(ref.part_number == this.props.worksheet.partNumber) {
                                    cycleTime = parseInt(ref.cycle_time);
                                }
                            });
                        }

                        const jobCells = [
                            <td key="code">
                                <b>{job.code}</b>
                                {!!job.instruction.version && <small><br />{_('Instruction')} v{job.instruction.version}</small>}
                                {!!cycleTime && <small><br />{_('CT')}: {cycleTime}s, {this.calculateCycleTime(cycleTime)} {_('parts/hr')}</small>}
                            </td>,
                            <td key="info">
                                <b>{job.title}</b><br />
                                {
                                    job.instruction.document &&
                                    job.instruction.document.url &&
                                    <span>
                                            <Tooltip content={_('Download instruction file') + ' ' + job.instruction.document.filename} className="target">
                                                <a href={job.instruction.document.url} target="_blank" rel="noopener noreferrer">
                                                    <small style={{whiteSpace: 'nowrap'}}>{_('Instruction File')} <FaIcon icon={'download'} /></small>
                                                </a>
                                            </Tooltip><br />
                                        </span>
                                }
                                {
                                    job.instruction.highlights &&
                                    job.instruction.highlights.length > 0 &&
                                    <span>
                                            <Tooltip content={_('Security Notices')} className="target">
                                                <small style={{whiteSpace: 'nowrap'}} onClick={() => this.props.flashMessageActions.set(<Highlights messages={job.instruction.highlights} />)}>
                                                    {_('Security Notices')} <FaIcon icon={'exclamation-triangle'} />
                                                </small>
                                            </Tooltip>
                                        </span>
                                }
                            </td>
                        ];

                        if(key == 0) {
                            content.push(<tr key={key} className={classNames({noBorder: multiJob})}>
                                <td>
                                    {multiJob ? _('Jobs') : _('Job')}
                                </td>
                                {jobCells}
                                <td style={{textAlign: 'right'}}>
                                    <div className={classNames({rightButtons: multiJob})}>
                                    {
                                        !!this.props.worksheet &&
                                        <Notice
                                            value={this.props.worksheet.comment}
                                            title={_('Current Work Comment')}
                                            onSave={(value) => this.props.worksheetActions.setComment(value)}
                                            hideTitle={true}
                                        />
                                    }

                                    <span>
                                        <Tooltip content={_('Job Stats')} className="target">
                                            <button className="smallButton" onClick={() => this.props.flashMessageActions.set(<Stats messages={['test']} />)} style={{marginBottom: '0.3em', marginLeft: '0.3em'}}>
                                                <FaIcon icon={'chart-bar'} /><span className="hideMobile"> {_('Stats')}</span>
                                            </button>
                                        </Tooltip>
                                    </span>

                                    {
                                        !this.props.batch &&
                                        <span>
                                            <Tooltip content={_('Finish Job')} className="target">
                                                <button className="smallButton" onClick={() => { this.finishJob(true); }} style={{marginLeft: '0.3em', marginBottom: '0.3em'}}>
                                                    <FaIcon icon={'flag-checkered'} /><span className="hideMobile"> {_('Finish')}</span>
                                                </button>
                                            </Tooltip>
                                        </span>
                                    }
                                    </div>
                                </td>
                            </tr>);
                        } else {
                            content.push(<tr key={key} className={classNames({noBorder: key != jobsCount - 1})}>
                                <td></td>
                                {jobCells}
                                <td></td>
                            </tr>);
                        }
                    });
                }

                if(this.props.worksheet) {
                    let cycleTime = 0;
                    if(this.props.work && this.props.work.jobs) {
                        this.props.work.jobs.forEach((job) => {
                           if(job.refs) {
                               job.refs.forEach((ref) => {
                                   if(ref.part_number == this.props.worksheet.partNumber) {
                                       cycleTime += parseInt(ref.cycle_time);
                                   }
                               });
                           }
                        });
                    }

                    content.push(
                        <tr key="part">
                            <td>
                                {_('Part')}
                            </td>
                            <td>
                                <b>{this.props.worksheet.partNumber}</b>
                            </td>
                            <td>
                                <b>{this.props.worksheet.partTitle}</b>
                                {
                                    !!cycleTime && this.props.work.jobs.length == 1 &&
                                    <div className="cycleTime">
                                        {this.props.work.jobs.length == 1 ? _('CT') : _('Total CT')}: {cycleTime}s, {this.calculateCycleTime(cycleTime)} {_('parts/hr')}
                                    </div>
                                }
                            </td>
                            <td>
                            </td>
                        </tr>
                    );
                }

                if(this.props.batch) {
                    let nok = 0;
                    let reworked = 0;

                    this.props.batch.defects.forEach((defect) => {
                        nok += parseInt(defect.nok);
                        reworked += parseInt(defect.reworked);
                    });

                    content.push(
                        <tr key="worksheet">
                            <td>
                                {_('Batch')}
                            </td>
                            <td>
                                <b>{this.props.batch.batchNumber ? this.props.batch.batchNumber : '---'}</b><br />
                                <div className="boxName">
                                    {this.props.batch.name ? this.props.batch.name : '---'}
                                </div>
                            </td>
                            <td>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>{_('Total')}</td>
                                            <td><b>{this.props.batch.amount}</b></td>
                                        </tr>
                                        <tr>
                                            <td>{_('Checked')}</td>
                                            <td><b>{this.props.batch.checked}</b></td>
                                        </tr>
                                        <tr>
                                            <td>{_('NOKs')} {multiJob && <i>(Σ)</i>}</td>
                                            <td><b>{nok}</b></td>
                                        </tr>
                                        <tr>
                                            <td>{_('Reworks')} {multiJob && <i>(Σ)</i>}</td>
                                            <td><b>{reworked}</b></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td style={{textAlign: 'right'}}>
                                {
                                    false &&
                                    <Notice
                                        value={this.props.batch.comment}
                                        title={_('Current Batch Comment')}
                                        onSave={(value) => this.props.batchActions.setComment(value)}
                                        hideTitle={true}
                                    />
                                }
                                {/*<Tooltip content={_('View Details')} className="target">
                                    <button className="smallButton"  onClick={() => this.props.flashMessageActions.set(<BatchCounts />)} style={{marginLeft: '0.3em', marginBottom: '0.3em'}}>
                                        <FaIcon icon={'eye'} /><span className="hideMobile"> {_('Details')}</span>
                                    </button>
                                </Tooltip>*/}
                                <Tooltip content={_('Edit Batch')} className="target">
                                    <button className="smallButton"  onClick={() => this.handleEdit()} style={{marginLeft: '0.3em', marginBottom: '0.3em'}}>
                                        <FaIcon icon={'pencil-alt'} /><span className="hideMobile"> {_('Edit')}</span>
                                    </button>
                                </Tooltip>
                                <Tooltip content={_('Finish Batch')} className="target">
                                    <button className="smallButton" onClick={() => { this.finishBatch(); }} style={{marginLeft: '0.3em', marginBottom: '0.3em'}}>
                                        <FaIcon icon={'flag-checkered'} /><span className="hideMobile"> {_('Finish')}</span>
                                    </button>
                                </Tooltip>
                            </td>
                        </tr>
                    );
                }

                return <table id="currentWork" cellPadding={0} cellSpacing={0}>
                    <tbody>
                        {content}
                    </tbody>
                </table>;
            }
        }

    }
)
