import {combineReducers} from 'redux';
import work from '../modules/work';
import worksheet from '../modules/worksheet';
import batch from '../modules/batch';
import flashMessage from '../modules/flashMessage';
import unfinishedWorksheets from '../modules/unfinishedWorksheets';
import qualityAssurance from '../modules/qualityAssurance';

export default combineReducers({
    work,
    worksheet,
    batch,
    flashMessage,
    unfinishedWorksheets,
    qualityAssurance,
});
