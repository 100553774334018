import React from 'react';
import Page from './page';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from '../redux/bindActionCreators';
import * as flashMessageActions from '../redux/modules/flashMessage';
import * as C from '../utils/constants';
import {isAuthenticated, authenticate} from '../utils/auth';
import ErrorMessages from '../components/errorMessages';
import Login from '../forms/login';
import ApiClient from '../utils/apiClient';
import * as locale from '../locales/locale';
import * as worksheetActions from "../redux/modules/worksheet";
import * as batchActions from "../redux/modules/batch";
import * as workActions from "../redux/modules/work";
import * as qualityAssuranceActions from "../redux/modules/qualityAssurance";
import * as unfinishedWorksheetsActions from "../redux/modules/unfinishedWorksheets";

export default connect(
    (state, props) => {
        return {
            flashMessage: state.flashMessage,
        }
    },
    (dispatch) => {
        return {
            flashMessageActions: bindActionCreators(flashMessageActions, dispatch),
            worksheetActions: bindActionCreators(worksheetActions, dispatch),
            batchActions: bindActionCreators(batchActions, dispatch),
            workActions: bindActionCreators(workActions, dispatch),
            unfinishedWorksheetsActions: bindActionCreators(unfinishedWorksheetsActions, dispatch),
            qualityAssuranceActions: bindActionCreators(qualityAssuranceActions, dispatch),
        }
    }
)(
    class LoginPage extends Page {

        static defaultProps = {
            authRequired: false,
            useEnvelope: true,
        };


        constructor(props) {
            super(props);

            this.state = {
                login: false,
            };

            this.api = new ApiClient(this.props);
        }


        componentDidMount() {
            this.props.flashMessageActions.clear();
        }


        async handleSubmit(values) {
            try {
                const result = await authenticate(values.username, values.password, values.token);

                // poznamka: v tomto momente je este this.api.userId null, nie j mozne pouzit
                const userId = result.data ?  result.data.id : null

                const response = userId !== null ? await this.api.get(C.workGroup + '/operators/' + userId + '/status') : null;
                if(response && response.worksheets && response.worksheets.length) {
                    this.props.unfinishedWorksheetsActions.set(response.worksheets);
                }

                const lang = result.data && result.data.lang ? result.data.lang : null;
                if(lang && C.languages.indexOf(lang) >= 0 && locale.getLang() != lang) {
                    locale.setLang(lang);
                    window.location.reload();
                }

                this.props.flashMessageActions.clear();
                this.setState({
                    login: true,
                });
            } catch (error) {
                this.refs.login.refs.login.refs.token.setValue('');
                this.refs.login.refs.login.refs.handScan.openScan(true);
                this.props.flashMessageActions.set(<ErrorMessages parent={this} errorCode={C.errors.BAD_LOGIN} errorMessage={error.message} />);
            }
        }


        getContent(state) {
            if(isAuthenticated() || this.state.login) {
                return <Redirect
                    to={{
                        pathname: C.routes.HOMEPAGE,
                        state: {},
                    }}
                />;
            }

            this.props.worksheetActions.clear();
            this.props.unfinishedWorksheetsActions.clear();
            this.props.batchActions.clear();
            this.props.workActions.clear();
            this.props.qualityAssuranceActions.clear();

            return <div className="center">
                <Login
                    ref="login"
                    onSubmit={(values) => this.handleSubmit(values.login)}
                />
            </div>;
        }

    }
)
