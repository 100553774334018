import React from 'react';
import PropTypes from 'prop-types';
import FlashMessage from './flashMessage';
import * as C from '../utils/constants';
import {_} from '../locales/gettext';


export default class Highlights extends React.Component {

    static propTypes = {
        messages: PropTypes.array.isRequired,
        title: PropTypes.string,
    };


    static defaultProps = {
        title: _('This instruction has security notices'),
    };


    render() {
        let messages = [];
        this.props.messages.forEach((item, key) => {
            messages.push(<li key={key}>{item}</li>);
        });

        return <FlashMessage fullScreen={true} closeButton={true} okButton={true} type={C.flashMessages.ALERT}>
            <h3>{this.props.title}:</h3>
            <ul className="securityNotices">
                {messages}
            </ul>
        </FlashMessage>;
    }

}
