import React from 'react';
import PropTypes from 'prop-types';
import FlashMessage from './flashMessage';
import * as C from '../utils/constants';
import {_} from '../locales/gettext';
import Input from '../forms/input';
import FaIcon from './faIcon';
import classNames from "classnames";

export default class QualityAssuranceSelect extends React.Component {
    static propTypes = {
        data: PropTypes.array.isRequired,
        onStart: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
    };


    constructor(props) {
        super(props);

        this.state = {
            error: false,
            selectedJobs: [],
        };
    }


    handleValue(str) {
        let value;
        let count = 0;
        if(str) {
            this.props.data.forEach((item) => {
                value = str.trim().toLowerCase();
                if (value && item.code.trim().toLowerCase() == value) {
                    this.handleJob(item.code);
                    count++;
                }
            });
        }

        if(!count) {
            this.handleJob(null);
        }

        this.setState({error: !count});
    }


    handleHandScan() {
        setTimeout(() => {
            if(this.refs.code && this.refs.code.refs.handScan) {
                this.refs.code.refs.handScan.openScan(true);
            }
        }, 1000);
    }


    handleJob(jobCode) {
        if(jobCode) {
            this.setState({selectedJobs: [jobCode]});
        } else {
            this.setState({selectedJobs: []});
        }
    }


    handleSelect() {
        this.props.onStart(this.state.selectedJobs);
    }


    render() {
        let rows = [];

        this.props.data.forEach((item, key) => {
            const selected = this.state.selectedJobs.indexOf(item.code) >= 0;
            rows.push(<tr key={key}>
                <td>{item.code}</td>
                <td>{item.title}</td>
                {/*<td>{item.checked || 0}/{item.total || 0}</td>*/}
                <td>
                    <button className={classNames({smallButton: true, disabled: !selected, pointer: true})} onClick={() => this.handleJob(item.code)}>
                        <FaIcon icon={selected ? 'check' : 'times'} />
                    </button>
                </td>
            </tr>);
        });

        if(!rows.length) {
            rows.push(<tr>
                <td colSpan={4} className="empty">{_('no jobs found')}</td>
            </tr>)
        }

        const selectedCount = this.state.selectedJobs.length;

        return <FlashMessage
            zIndex={1000}
            fullScreen={true}
            closeButton={true}
            okButton={true}
            okButtonTitle={_('Close')}
            type={C.flashMessages.ALERT}
            onClose={() => {
                this.props.onClose();
            }}
        >
            <h3 className="scanHide">
                {_('Available EOL Jobs')}:
            </h3>
            <table className="jobsTable scanHide" border="1" cellPadding="10" cellSpacing="0">
                <tbody>
                <tr key="heading" className="headRow">
                    <th>{_('EOL Job')}</th>
                    <th>{_('Title')}</th>
                    {/*<th>{_('Items checked/total')}</th>*/}
                    <th>{_('Select')}</th>
                </tr>
                {rows}
                </tbody>
            </table>
            <h3 className="scanHide" style={{marginTop: '1.5em', marginBottom: '0.7em'}}>
                {_('EOL Job Code')}
            </h3>
            <div className="formInput">
                <Input
                    ref="code"
                    required={true}
                    onChange={(value) => this.handleValue(value)}
                    convertChars={true}
                    handScan={true}
                    handScanOpen={true}
                    placeholder={_('EOL Job Code')}
                    onScanSubmit={() => this.handleHandScan()}
                    error={this.state.error ? _('Code not Found') : null}
                />
            </div>
            <button className={classNames({disabled: !selectedCount})} onClick={() => { this.handleSelect(); }} disabled={!selectedCount}>
                {_('Start with selected')}
            </button>
        </FlashMessage>;
    }

}

