import React from 'react';
import {connect} from 'react-redux';
import * as C from '../utils/constants';
import Page from './page';
import QualityAssurance from "../components/qualityAssurance";
import {Redirect} from "react-router-dom";


export default connect(
    (state, props) => {
        return {
            flashMessage: state.flashMessage,
            work: state.work,
            unfinishedWorksheets: state.unfinishedWorksheets,
        }
    },
    (dispatch) => {
        return {
        }
    }
)(
    class QualityAssurancePage extends Page {

        getContent(state) {
            if(
                this.props.work ||
                (this.props.unfinishedWorksheets && this.props.unfinishedWorksheets.length)
            ) {
                return <Redirect to={C.routes.HOMEPAGE} />;
            }

            return <div className="center">
                <QualityAssurance
                    defaultState={state}
                    history={this.props.history}
                />
            </div>;
        }

    }
)
