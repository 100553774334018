import React from 'react';
import PropTypes from 'prop-types';
import Webcam from "react-webcam";
import Tooltip from 'react-tooltip-lite';
import classNames from 'classnames';
import {sprintf} from 'sprintf-js';
import FaIcon from './faIcon';
import FlashMessage from './flashMessage';
import * as C from '../utils/constants';
import {_} from '../locales/gettext';
import ComponentHelpers from "../utils/componentHelpers";

export default class Documents extends React.Component {

    static propTypes = {
        documents: PropTypes.array.isRequired,
        allowAdd: PropTypes.array,
        onAdd: PropTypes.func,
        allowRemove: PropTypes.bool,
        webcamOpen: PropTypes.bool,
        onRemove: PropTypes.func,
    };


    static defaultProps = {
        allowAdd: [],
        allowRemove: false,
        webcamOpen: false,
        photoSerie: null,
    }


    constructor(props) {
        super(props);

        this.state = {
            screenshot: props.webcamOpen,
            webcam: false,
            detail: null,
            snap: null,
            photoSerie: null,
        };

        this.snapTimeout = null;
        this.componentHelpers = new ComponentHelpers(this, null);
    }


    handleAddImage() {
        this.setState({
            screenshot : true,
            webcam: false,
        });
    }


    handleAddPhotoSerie() {
        this.setState({
            photoSerie : [],
            webcam: false,
        });
    }


    handleScreenshot(close) {
        const dataUri = this.refs.webcam.getScreenshot({})
        let image = {
            type: 'image/jpeg',
            base64: dataUri,
        };

        if(this.refs.serieSelect) {
            image.serieType = this.refs.serieSelect.value;
        }

        this.props.onAdd(image);

        if(close) {
            this.setState({
                screenshot : false,
                webcam: false,
            });
        } else {
            this.setState({snap: true});
            setTimeout(() => {
                this.setState({snap: false});
            }, 50);
        }
    }


    renderAddButtons(add) {
        let result = [];
        add.forEach((item) => {
            switch(item) {
                case C.documentType.IMAGE:
                    result.push(<button
                        key={item}
                        className={classNames({smallButton: true, pointer: true})}
                        onClick={() => this.handleAddImage()}
                    >
                        {_('Add Image')}
                    </button>);
                    break;
                case C.documentType.PHOTO_SERIE:
                    result.push(<button
                        key={item}
                        className={classNames({smallButton: true, pointer: true})}
                        onClick={() => this.handleAddPhotoSerie()}
                    >
                        {_('Add Photo.')}
                    </button>);
                    break;
            }
        });
        return result;
    }


    renderPhotoSerieSelect() {
        if(this.props.photoSerie) {
            let options = [];

            this.props.photoSerie.forEach((item) => {
                let exists = false;
                this.props.documents.forEach((item2) => {
                    if(item2.serieType == item.type) {
                        exists = true;
                    }
                });
                if(!exists) {
                    options.push(<option value={item.type}>{item.name}{item.required ? '*' : ''} ({item.description})</option>);
                }
            });

            options.push(<option value={null}>{_('Other photo')}</option>);
            return <div style={{marginBottom: '1.5em'}}>
                <b style={{paddingRight: '1em'}}>{_('Select photo type')}</b>
                <select ref="serieSelect" style={{fontSize: '1.1em', padding: '0.3em 0.5em'}}>
                    {options}
                </select>
            </div>;
        }
        return null;
    }


    render() {
        if(this.state.screenshot) {
            const webcam = !!this.state.webcam;

            return <FlashMessage
                fullScreen={true}
                closeButton={true}
                type={C.flashMessages.COMMON}
                zIndex={1000}
                onClose={() => this.setState({
                    screenshot: false,
                    webcam: false,
                })}
            >
                <div className={classNames('webcam')}>
                    <div className={classNames('snap', {visible: this.state.snap})}/>
                    {
                        !webcam &&
                        <span className="empty">{_('Waiting for webcam start...')}</span>
                    }
                    <Webcam
                        audio={false}
                        ref="webcam"
                        screenshotFormat="image/jpeg"
                        minScreenshotWidth={1920}
                        onUserMedia={() => this.setState({webcam: true})}
                        videoConstraints={{
                            facingMode: "environment",
                        }}
                    />
                </div>
                <div style={{marginTop: '2em'}} className="scanHide">
                    {this.renderPhotoSerieSelect()}
                    {
                        webcam &&
                        <button
                            onClick={() => this.handleScreenshot()}
                            className={classNames({pointer: true})}
                        >
                            <FaIcon icon="camera"/>&nbsp;{_('Take photo')}
                        </button>
                    }
                    {
                        !webcam &&
                        <button
                            className={classNames({disabled: true, pointer: false})}
                        >
                            <FaIcon icon="camera"/>&nbsp;{_('Take photo')}
                        </button>

                    }
                    {
                        webcam &&
                        <button
                            style={{marginLeft: '1em'}}
                            onClick={() => this.handleScreenshot(true)}
                            className={classNames({pointer: true})}
                        >
                            <FaIcon icon="camera"/>&nbsp;{_('Take photo & Close')}
                        </button>
                    }
                    {
                        !webcam &&
                        <button
                            style={{marginLeft: '1em'}}
                            className={classNames({disabled: true, pointer: false})}
                        >
                            <FaIcon icon="camera"/>&nbsp;{_('Take photo & Close')}
                        </button>

                    }
                    <button
                        style={{minWidth: 'auto', marginLeft: '1em'}}
                        onClick={() => this.setState({
                            screenshot: false,
                            webcam: false,
                        })}><FaIcon icon="times"/>&nbsp;{_('Skip')}
                    </button>
                </div>
            </FlashMessage>
        } else if(this.state.photoSerie) {
            let missing = [];
            this.props.photoSerie.forEach((item) => {
                if(item.required) {
                    let itemFound = false;
                    this.state.photoSerie.forEach((item2) => {
                        if(item2.serieType == item.type) {
                            itemFound = true;
                        }
                    });
                    if(!itemFound) {
                        missing.push(item);
                    }
                }
            });
            return <FlashMessage
                fullScreen={true}
                closeButton={true}
                type={C.flashMessages.COMMON}
                zIndex={1000}
                onClose={() => this.setState({
                    photoSerie: null,
                    screenshot: false,
                    webcam: false,
                })}
            >
                <Documents
                    documents={this.state.photoSerie}
                    photoSerie={this.props.photoSerie}
                    allowAdd={[C.documentType.IMAGE]}
                    allowRemove={true}
                    onAdd={(image) => {
                        this.state.photoSerie.push(image);
                        this.forceUpdate();
                    }}
                    onRemove={(index) => {
                        this.state.photoSerie.splice(index, 1);
                        this.forceUpdate();
                    }}
                />
                <div style={{marginTop: '2em'}}>
                    {
                        missing.length == 0 &&
                        <button
                            onClick={() => {
                                this.props.onAdd({
                                    type: 'photo_serie',
                                    data: this.state.photoSerie,
                                });
                                this.setState({
                                    photoSerie: null,
                                })
                            }}
                        >
                            {_('Add photos & close')}
                        </button>
                    }
                    {
                        missing.length > 0 &&
                        <div>
                            <h4>
                                {_('Photo documentation is not complete, missing:')}
                            </h4>
                            <ul className="securityNotices">
                                {
                                    missing.map((item) => {
                                        return <li><b>{item.name}</b> ({item.description})</li>;
                                    })
                                }
                            </ul>
                            <Tooltip content={sprintf(_('Missing %s of required photos'), missing.length)} styles={{display: 'inline-block'}}>
                                <button
                                    className="disabled"
                                    onClick={() => {}}
                                >
                                    {_('Add photos & close')}
                                </button>
                            </Tooltip>
                        </div>
                    }
                </div>
            </FlashMessage>;
        } else {
            let rows = [];
            let controlColumn = this.props.allowAdd.length || this.props.allowRemove;
            this.props.documents.forEach((document, key) => {
                let type = null;
                if(document.type) {
                    type = document.type;
                } else if(document.file && document.file.mimeType) {
                    type = document.file.mimeType;
                }
                type = this.componentHelpers.getDocumentType(type);
                let details = null;

                if(this.props.photoSerie && document.serieType) {
                    this.props.photoSerie.forEach((item) => {
                        if(item.type == document.serieType) {
                            details = item.name;
                        }
                    });
                }

                let full = false;
                if(this.state.detail) {
                    if(key == this.state.detail.key) {
                        if (typeof this.state.detail.index != 'undefined') {
                            full = this.state.detail.index;
                        } else {
                            full = true;
                        }
                    }
                }

                rows.push(<tr key={key}>
                    <td>
                        {this.componentHelpers.getDocumentTypeTitle(type)}
                        {
                            !!details &&
                            <i> ({details})</i>
                        }
                    </td>
                    <td>
                        <div>
                            {
                                this.componentHelpers.getDocumentDetail(
                                    type,
                                    document,
                                    (index) => this.setState({detail: {key: key, index: index}}),
                                    full,
                                    () => this.setState({detail: null})
                                )
                            }
                        </div>
                    </td>
                    {
                        controlColumn &&
                        <td>
                            {
                                this.props.allowRemove &&
                                <button
                                    className={classNames({smallButton: true, pointer: true})}
                                    onClick={() => this.props.onRemove(key)}
                                >
                                    <Tooltip content={_('Remove document')}>
                                        <FaIcon icon="trash-alt" />
                                    </Tooltip>
                                </button>
                            }
                        </td>
                    }
                </tr>);
            });

            if(!rows.length) {
                rows.push(<tr key="empty">
                    <td colSpan={controlColumn ? 3 : 2} className="empty">{_('no documents found')}</td>
                </tr>);
            }

            return <div className="documents">
                <table className="jobsTable scanHide" border="1" cellPadding="10" cellSpacing="0">
                    <tbody>
                    <tr key="heading" className="headRow">
                        <th>{_('Document')}</th>
                        <th>{_('Detail')}</th>
                        {
                            controlColumn &&
                            <th style={{width: 200}}>
                                {this.renderAddButtons(this.props.allowAdd)}
                            </th>
                        }
                    </tr>
                    {rows}
                    </tbody>
                </table>
            </div>;
        }
    }

}
