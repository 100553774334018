const SET = '@worksheet/SET';
const CLEAR = '@worksheet/CLEAR';
const SET_COMMENT = '@worksheet/SET_COMMENT';

const worksheet = (state = null, action) => {
    let result = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case SET:
            return {
                partNumber: action.partNumber,
                partTitle: action.partTitle,
                id: parseInt(action.id),
                comment: null,
            };

        case CLEAR:
            return null;

        case SET_COMMENT:
            result.comment = action.comment;
            return result;

        default:
            return state
    }
}

export default worksheet


export const set = (partNumber, partTitle, id) => ({
    type: SET,
    partNumber,
    partTitle,
    id,
});


export const clear = () => ({
    type: CLEAR,
});


export const setComment = (comment) => ({
    type: SET_COMMENT,
    comment,
});