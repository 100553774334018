import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'react-tooltip-lite';
import classNames from 'classnames';
import FaIcon from './faIcon';
import FlashMessage from './flashMessage';
import * as C from '../utils/constants';
import {_} from '../locales/gettext';

export default class Notice extends React.Component {

    static propTypes = {
        onSave: PropTypes.func,
        buttonTitle: PropTypes.string,
        title: PropTypes.string,
        tooltip: PropTypes.string,
        value: PropTypes.string,
        overlay: PropTypes.bool,
        hideTitle: PropTypes.bool,
        buttonClass: PropTypes.string,
        icon: PropTypes.string,
    };


    static defaultProps = {
        title: _('Comment'),
        buttonTitle: _('Comment'),
        tooltip: _('Change comment'),
        buttonClass: 'smallButton',
        overlay: true,
        hideTitle: false,
        icon: 'comment',
    }

    constructor(props) {
        super(props);

        this.state = {
            open: false,
        };
    }


    handleButton() {
        const open = this.state.open;
        this.setState({open: !open});
    }


    handleSave() {
        if(this.props.onSave) {
            this.props.onSave(this.refs.notice.value);
        }
        this.handleClose();
    }


    handleClose() {
        this.setState({open: false});
    }


    render() {
        return <div className="notice">
            <div className="noticeButton">
                <Tooltip content={this.props.tooltip} className="target">
                    <button className={this.props.buttonClass} onClick={() => this.handleButton()}><FaIcon icon={this.props.icon} />
                        {!!this.props.buttonTitle && <span className={classNames({hideMobile: this.props.hideTitle})}>&nbsp;{this.props.buttonTitle}</span>}
                    </button>
                </Tooltip>
            </div>
            {
                this.state.open &&
                <FlashMessage
                    fullScreen={true}
                    type={C.flashMessages.COMMON}
                    closeButton={true}
                    okButton={false}
                    onClose={() => this.handleClose()}
                    zIndex={99999}
                >
                    <h2>{this.props.title}</h2>
                    <textarea ref="notice" defaultValue={this.props.value} /><br />
                    <button onClick={() => this.handleSave()}><FaIcon icon={'save'} /> {_('Save & Close')}</button>
                </FlashMessage>
            }
        </div>;
    }

}
