import React from 'react'
import PropTypes from 'prop-types'
import CodeReader from '../components/codeReader'
import HandScan from '../components/handScan'
import {_} from '../locales/gettext'
import cn from 'classnames'
import {convertChar} from '../utils/utils'

export default class Input extends React.Component {
  static propTypes = {
    scan: PropTypes.bool,
    handScan: PropTypes.bool,
    handScanOpen: PropTypes.bool,
    handScanParseJSON: PropTypes.bool,
    handScanTimeout: PropTypes.number,
    onScanSubmit: PropTypes.func,
    required: PropTypes.bool,
    check: PropTypes.bool,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    defaultValue: PropTypes.any,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    requiredPrefix: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    possibleValues: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    convertChars: PropTypes.bool,
    autoFocus: PropTypes.bool,
  }

  static defaultProps = {
    scan: true,
    handScan: false,
    handScanOpen: false,
    handScanParseJSON: false,
    handScanTimeout: 1500,
    required: true,
    check: false,
    type: 'text',
    defaultValue: '',
    disabled: false,
    requiredPrefix: '',
    possibleValues: '',
    convertChars: false,
    autoFocus: false,
    onKeyDown: () => {},
  }

  getValue() {
    let value = typeof this.state.value === 'string' ? this.state.value.trim() : this.state.value
    if (this.props.requiredPrefix) {
      const requiredPrefix = this.parseRequiredPrefix(this.props.requiredPrefix)
      for (let i in requiredPrefix) {
        if (value.startsWith(requiredPrefix[i])) {
          value = value.substring(requiredPrefix[i].length)
          break
        }
      }
    }
    return value
  }

  setValue(value) {
    return new Promise((resolve) => {
      this.setState(
        {
          value: value,
        },
        () => {
          resolve(true)
        }
      )
    })
  }

  constructor(props) {
    super(props)

    this.state = {
      value: props.defaultValue,
    }
  }

  handleScan(data) {
    this.handleChange(data)

    if (this.props.handScan) {
      setTimeout(() => {
        if (this.refs.handScan) {
          this.refs.handScan.openScan(false)
        }
      }, 100)
    }

    if (this.props.onScanSubmit) {
      this.props.onScanSubmit()
    }
  }

  handleHandScan(data) {
    this.handleChange(data)

    if (this.props.handScanOpen) {
      setTimeout(() => {
        if (this.refs.handScan) {
          this.refs.handScan.openScan(true)
        }
      }, this.props.handScanTimeout)
    }

    if (this.props.onScanSubmit) {
      this.props.onScanSubmit()
      if (this.props.handScanOpen) {
        setTimeout(() => {
          this.setState({value: ''})
        }, this.props.handScanTimeout)
      }
    }
  }

  handleChange(value) {
    /*if(this.props.type === 'number') {
            value = value.replace( /^\D+/g, '');
        }*/
    this.setState({
      value: value,
    })
    if (this.props.onChange) {
      this.props.onChange(value)
    }
  }

  handleKeyPress(e) {
    if (this.props.convertChars) {
      let value = e.target.value
      let newValue = ''

      for (let i = 0; i < value.length; i++) {
        newValue += convertChar(value[i])
      }

      this.handleChange(newValue)
    } else {
      return this.handleChange(e.target.value)
    }
  }

  handleKeyDown(e) {
    if (this.props.onKeyDown) {
      this.props.onKeyDown(e)
    }
  }

  isValid() {
    return !this.isEmpty() && this.isRequiredPrefix() && this.isPossibleValue() && this.isNumber()
  }

  isNumber() {
    return this.props.type !== 'number' || !isNaN(this.getValue())
  }

  isRequiredPrefix() {
    if (!this.props.requiredPrefix) {
      return true
    }

    let value = this.state.value.trim()
    const requiredPrefix = this.parseRequiredPrefix(this.props.requiredPrefix)

    for (let i in requiredPrefix) {
      if (value.length > 1 && value.startsWith(requiredPrefix[i])) {
        return true
      }
    }
  }

  parseRequiredPrefix(prefix) {
    let requiredPrefix = prefix
    if (!Array.isArray(requiredPrefix)) {
      requiredPrefix = [requiredPrefix]
    }

    return requiredPrefix
  }

  isPossibleValue() {
    if (!this.props.possibleValues) {
      return true
    }

    let value = this.getValue()
    let possibleValues = this.parsePossibleValues(this.props.possibleValues)

    for (let i in possibleValues) {
      if (value.length > 1 && value == possibleValues[i]) {
        return true
      }
    }
  }

  parsePossibleValues(values) {
    let possibleValues = values
    if (!Array.isArray(possibleValues)) {
      possibleValues = [possibleValues]
    }

    return possibleValues
  }

  isEmpty() {
    return this.props.required && this.getValue() === ''
  }

  render() {
    const requiredPrefix = this.parseRequiredPrefix(this.props.requiredPrefix)
    const possibleValues = this.parsePossibleValues(this.props.possibleValues)

    return (
      <div className='inputContainer'>
        {this.props.scan && <CodeReader onScan={(data) => this.handleScan(data)} />}
        {this.props.handScan && (
          <HandScan
            ref='handScan'
            onScan={(data) => {
              this.handleHandScan(data)
            }}
            convertChars={this.props.convertChars}
            open={this.props.handScanOpen}
            placeholder={this.props.placeholder}
            parseJSON={this.props.handScanParseJSON}
          />
        )}
        <input
          autoFocus={this.props.autoFocus}
          type={this.props.type == 'number' ? 'text' : this.props.type}
          value={this.state.value}
          className={cn({errorInput: this.props.check && !this.isValid(), scanHide: true})}
          onChange={(e) => this.handleKeyPress(e)}
          onKeyDown={(e) => this.handleKeyDown(e)}
          placeholder={this.props.placeholder}
          disabled={this.props.disabled}
        />
        {this.props.check && this.isEmpty() && (
          <div className='validateMessage'>
            {_('Field is required')}
            {this.props.requiredPrefix && (
              <span>
                &nbsp;({_('except for prefix')}
                &nbsp;<b>{requiredPrefix.join(', ')}</b>)
              </span>
            )}
          </div>
        )}
        {this.props.check && !this.isEmpty() && !this.isRequiredPrefix() && (
          <div className='validateMessage'>
            {_('Missing required prefix')}
            &nbsp;<b>{requiredPrefix.join(', ')}</b>
          </div>
        )}
        {this.props.check && !this.isEmpty() && !this.isPossibleValue() && (
          <div className='validateMessage'>
            {_('Unexpected value')}
            &nbsp;({_('possible values')} <b>{possibleValues.join(', ')}</b>)
          </div>
        )}
        {this.props.check && !this.isEmpty() && this.isRequiredPrefix() && !this.isNumber() && (
          <div className='validateMessage'>
            {_('Enter number')}
            {this.props.requiredPrefix && (
              <span>
                &nbsp;({_('except for prefix')}
                &nbsp;<b>{requiredPrefix.join(', ')}</b>)
              </span>
            )}
          </div>
        )}
        {this.props.error && <div className='validateMessage'>{this.props.error}</div>}
      </div>
    )
  }
}
