import React from 'react';
import Tooltip from 'react-tooltip-lite';
import {_} from '../locales/gettext';
import classNames from 'classnames';
import FaIcon from './faIcon';

const storage = localStorage;
const min = 10;
const max = 20;

export default class FontSize extends React.Component {

    constructor(props) {
        super(props);
        let size = Math.max(min, Math.min(max, parseInt(storage.getItem('fontSize')) || 13));

        this.state = {
            size: size,
        };
    }


    componentDidMount() {
        this.setSize(this.state.size);
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        this.setSize(this.state.size);
    }


    setSize(size) {
        storage.setItem('fontSize', size);
        document.getElementsByTagName('body')[0].style.fontSize = (size / 10) + 'em';
    }


    handleUp() {
        const size = this.state.size < max ? this.state.size + 1 : max;
        this.setState({size: size});
    }


    handleDown() {
        const size = this.state.size > min ? this.state.size - 1 : min;
        this.setState({size: size});
    }


    render() {
        return <div id="fontSize">
            <div onClick={() => this.handleDown()} style={{display: 'inline-block', marginRight: '0.5em'}} className={classNames({icon: true, disabled: this.state.size <= min})}>
                <Tooltip content={_('Zoom-out text')}>
                    <FaIcon icon={'search-minus'} />
                </Tooltip>
            </div>
            <div onClick={() => this.handleUp()} style={{display: 'inline-block'}} className={classNames({icon: true, disabled: this.state.size >= max})}>
                <Tooltip content={_('Zoom-in text')}>
                    <FaIcon icon={'search-plus'} />
                </Tooltip>
            </div>
        </div>;
    }

}
