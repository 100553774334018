import React from 'react'
import {connect} from 'react-redux'
import * as C from '../utils/constants'
import Page from './page'
import Work from '../components/work'
import {bindActionCreators} from '../redux/bindActionCreators'
import * as workActions from '../redux/modules/work'
import * as flashMessageActions from '../redux/modules/flashMessage'
import {Redirect} from 'react-router-dom'

export default connect(
  (state, props) => {
    return {
      flashMessage: state.flashMessage,
      work: state.work,
      qualityAssurance: state.qualityAssurance,
      unfinishedWorksheets: state.unfinishedWorksheets,
    }
  },
  (dispatch) => {
    return {
      flashMessageActions: bindActionCreators(flashMessageActions, dispatch),
      workActions: bindActionCreators(workActions, dispatch),
    }
  }
)(
  class WorkPage extends Page {
    getContent(state) {
      //   if (this.props.work && !state) {
      //     this.props.workActions.clear()
      //   }
      if (
        this.props.qualityAssurance ||
        (!this.props.work &&
          this.props.unfinishedWorksheets &&
          this.props.unfinishedWorksheets.length &&
          (!state || !state.unfinished))
      ) {
        return <Redirect to={C.routes.HOMEPAGE} />
      }

      return (
        <div className='center'>
          <Work defaultState={state} />
        </div>
      )
    }
  }
)
