import React from 'react';
import PropTypes from 'prop-types';
import FaIcon from './faIcon';
import Tooltip from 'react-tooltip-lite';
import {connect} from 'react-redux';
import {bindActionCreators} from '../redux/bindActionCreators';
import * as qualityAssuranceActions from '../redux/modules/qualityAssurance';
import * as flashMessageActions from '../redux/modules/flashMessage';
import {_} from '../locales/gettext';
import ApiClient from '../utils/apiClient';
import Notice from './notice';
import ErrorMessage from './errorMessages';
import classNames from "classnames";
import QualityAssuranceEntity from "../utils/qualityAssuranceEntity";
import Inspections from "./inspections";
import * as C from "../utils/constants";
import ComponentHelpers from "../utils/componentHelpers";

export default connect(
    (state, props) => {
        return {
            work: state.work,
            qualityAssurance: state.qualityAssurance,
        }
    },
    (dispatch) => {
        return {
            qualityAssuranceActions: bindActionCreators(qualityAssuranceActions, dispatch),
            flashMessageActions: bindActionCreators(flashMessageActions, dispatch),
        }
    }
)(
    class CurrentQualityAssurance extends React.Component {

        static propTypes = {
            onFinish: PropTypes.func.isRequired,
        };


        constructor(props) {
            super(props);

            this.state = {
                inspections: false,
            };

            this.api = new ApiClient(this.props);
            this.qualityAssuranceEntity = new QualityAssuranceEntity(this.props.qualityAssurance);
            this.componentHelpers = new ComponentHelpers(this, this.api);
        }


        componentDidUpdate(prevProps, prevState, snapshot) {
            this.qualityAssuranceEntity.setState(this.props.qualityAssurance);
        }


        handleBack() {
            if(window.confirm(_('Your unsaved changes will be lost, are you sure?'))) {
                this.props.qualityAssuranceActions.setCurrentItem(null);
            }
        }


        async finishJob(save) {
            /*if(window.confirm(_('Finish job, are you sure?'))) {*/
                try {
                    // @todo
                    this.props.qualityAssuranceActions.clear();
                    this.props.onFinish();
                } catch(error) {
                    this.props.flashMessageActions.set(<ErrorMessage fullScreen={false} errorCode={error.code} errorMessage={error.message} />);
                }
            /*}*/
        }


        async reject(currentItem) {
            await this.finishInspection(currentItem);
        }


        async finishInspection(currentItem, notFoundComment, allOk) {
            const qualityAssurance = this.props.qualityAssurance;

            if(currentItem.new) {
                await this.componentHelpers.handleAsyncError(async () => {
                    const params = {
                        serial_number: currentItem.serial_number,
                        label: (currentItem.label !== '' ? currentItem.label : null) || null, // Note: not blank
                    }
                    const response = await this.api.post(C.qaGroup + '/jobs/' + qualityAssurance.code + '/subjects', params);
                    currentItem.id = response.id;
                    currentItem.new = false;
                    currentItem.current = true;
                    this.props.qualityAssuranceActions.updateItem(currentItem.serial_number, currentItem);
                }, null, false);
            }
            if(currentItem.id) {

            /*if(window.confirm(_('Finish item, are you sure?'))) {*/
                let inspection = null;
                for(let i in currentItem.inspections) {
                    if(currentItem.inspections[i].new) {
                        inspection = currentItem.inspections[i];
                    }
                }

                if(inspection) {
                    this.qualityAssuranceEntity.setState(this.props.qualityAssurance);
                    const currentItem = this.qualityAssuranceEntity.getCurrentItem();

                    // @todo sjednotit jmena atributu, at se to nemusi takto skarede parsovat
                    const postInspection = {
                        start: inspection.start.iso,
                        end: (new Date().toISOString()),
                        subject: currentItem.id,
                        results: inspection.results.map((item) => {
                            return {
                                defect: item.id,
                                state: item.state,
                                description: item.description,
                                documents: item.documents.map((document, key) => {
                                    return {
                                        contents: document.base64.replace('data:image/jpeg;base64,', ''), // @todo pryc s tim :-)
                                        mime: document.type,
                                        name: 'image_' + key + '.jpeg',
                                    }
                                }),
                            }
                        }),
                    };

                    if(notFoundComment) {
                        postInspection.results = [];
                        postInspection.subject_status = C.qaStatus.NOT_FOUND;
                        postInspection.note = notFoundComment === true ? '' : notFoundComment;
                    }

                    await this.componentHelpers.handleAsyncError(async () => {
                        await this.api.post(C.qaGroup + '/jobs/' + qualityAssurance.code + '/subjects/' + currentItem.id + '/inspections' + (allOk ? '/clean' : ''), {data: postInspection});
                        const response = await this.api.get(C.qaGroup + '/jobs/' + qualityAssurance.code);
                        this.qualityAssuranceEntity.setState(response);
                        this.props.qualityAssuranceActions.set(this.qualityAssuranceEntity.getState(true));
                    }, null, false);
                }

                this.props.qualityAssuranceActions.setCurrentItem(null);
            /*}*/
            }
        }


        showInspections() {
            this.setState({inspections: true});
        }


        render() {
            const qualityAssurance = this.props.qualityAssurance;
            this.qualityAssuranceEntity.setState(qualityAssurance);

            if(this.props.work || !qualityAssurance) {
                return null;
            } else {
                let content = [];
                const qualityAssuranceStats = this.qualityAssuranceEntity.getStats();
                const currentqualityAssuranceItem = this.qualityAssuranceEntity.getCurrentItem();
                let currentInspection = null;

                let checked = [];
                if(currentqualityAssuranceItem) {
                    if(currentqualityAssuranceItem.inspections) {
                        currentqualityAssuranceItem.inspections.forEach((item) => {
                            if(item.new) {
                                currentInspection = item;
                            }
                        });
                    }

                    this.props.qualityAssurance.defects.forEach((defect) => {
                        if(currentInspection && currentInspection.results) {
                            currentInspection.results.forEach((item) => {
                                if(
                                    item.id == defect.id &&
                                    checked.indexOf(item.id) < 0 &&
                                    item.state &&
                                    item.state != C.qaStatus.NOT_CHECKED
                                ) {
                                    checked.push(item.id);
                                }
                            });
                        }
                    });
                }

                content.push(<tr key="info" className={classNames('qaHead')}>
                    <td>{_('EOL Job')}</td>
                    <td><b>{qualityAssurance.code}</b></td>
                    <td><b>{qualityAssurance.title}</b></td>
                    <td style={{textAlign: 'right', borderBottom: 'solid 1px #d9d9d9'}} rowSpan={2}>
                        <Notice
                            value={qualityAssurance.notice}
                            title={_('Current EOL Job Comment')}
                            onSave={(value) => this.props.qualityAssuranceActions.setComment(value)}
                            hideTitle={true}
                        />
                        {
                            !currentqualityAssuranceItem &&
                            <span>
                                <Tooltip content={_('Finish EOL Job')} className="target">
                                    <button className="smallButton" onClick={() => { this.finishJob(true); }} style={{marginLeft: '0.3em', marginBottom: '0.3em'}}>
                                        <FaIcon icon={'flag-checkered'} /><span className="hideMobile"> {_('Finish')}</span>
                                    </button>
                                </Tooltip>
                            </span>
                        }
                    </td>
                </tr>);

                content.push(<tr key="items" className={classNames('qaItems')}>
                    <td>
                        {_('Stats')}
                    </td>
                    <td colSpan={2}>
                        <table>
                            <tbody>
                                <tr>
                                    <td>{_('Checked')}</td>
                                    <td><b>{qualityAssuranceStats.checked}</b> {_('of')} <b>{qualityAssuranceStats.total}</b></td>
                                </tr>
                                <tr>
                                    <td>{_('OK / NOK / Rework')}</td>
                                    <td>
                                        <b style={{display:'inline-block', marginLeft:'5px', color: 'green'}} title={_('OK')}>{qualityAssuranceStats.ok}</b> /
                                        <b style={{display:'inline-block', marginLeft:'5px', color: 'red'}} title={_('NOK')}>{qualityAssuranceStats.nok}</b> /
                                        <b style={{display:'inline-block', marginLeft:'5px', color: 'orange'}} title={_('Reworked')}>{qualityAssuranceStats.reworked}</b>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>);

                if(currentqualityAssuranceItem) {
                    content.push(<tr key="qualityAssuranceItems" className={classNames()}>
                        <td>
                            {_('Current Subject')}
                        </td>
                        <td colSpan={2}>
                            {_('Code')}: <i>{currentqualityAssuranceItem.serial_number ||  _('N/A')}</i><br />
                            {_('Position')}: <i>{currentqualityAssuranceItem.label || _('N/A')}</i>
                        </td>
                        <td style={{textAlign: 'right'}}>
                            <div className={classNames()}>
                                <span>
                                    <Tooltip content={_('Show Subject Inspections')} className="target">
                                        <button className="smallButton subdued" onClick={() => { this.showInspections(); }} style={{marginLeft: '0.3em', marginBottom: '0.3em'}}>
                                            <FaIcon icon={'eye'} /><span className="hideMobile"> {_('Inspections')}</span>
                                        </button>
                                    </Tooltip>
                                </span>

                                {
                                    !(currentInspection && currentInspection.results && currentInspection.results.length > 0) &&
                                    !currentqualityAssuranceItem.new &&
                                    <span style={{marginLeft: '0.3em', marginBottom: '0.3em'}}>
                                        <Notice
                                            title={_('EOL subject not found')}
                                            onSave={(comment) => this.finishInspection(currentqualityAssuranceItem, comment || true)}
                                            hideTitle={true}
                                            buttonTitle={_('Not found')}
                                            tooltip={_('EOL subject not found')}
                                            icon={_('exclamation-triangle')}
                                        />
                                    </span>
                                }
                                {
                                    this.props.qualityAssurance.defects.length <= checked.length &&
                                    <span>
                                        <Tooltip content={_('Finish Item')} className="target">
                                            <button className="smallButton" onClick={() => this.finishInspection(currentqualityAssuranceItem)} style={{marginLeft: '0.3em', marginBottom: '0.3em'}}>
                                                <FaIcon icon={'flag-checkered'} /><span className="hideMobile"> {_('Finish')}</span>
                                            </button>
                                        </Tooltip>
                                    </span>
                                }
                                {
                                    this.props.qualityAssurance.settings.singleButtonPass && currentInspection.results.length === 0 &&
                                    <span>
                                        <Tooltip content={_('Mark the subject as "Without defects". This will mark the subject OK or REWORKED, depending on its previous state.')} className="target">
                                            <button className="smallButton" onClick={() => this.finishInspection(currentqualityAssuranceItem, false, true)} style={{marginLeft: '0.3em', marginBottom: '0.3em'}}>
                                                <FaIcon icon={'check'} /><span className="hideMobile"> {_('Without defects')}</span>
                                            </button>
                                        </Tooltip>
                                    </span>
                                }
                                {
                                    this.props.qualityAssurance.settings.firstDefectRejects && currentInspection.results.filter((item) => item.state == 'present').length > 0 &&
                                    <span>
                                        <Tooltip content={_('Reject current subject')} className="target">
                                            <button className="smallButton" onClick={() => this.reject(currentqualityAssuranceItem)} style={{marginLeft: '0.3em', marginBottom: '0.3em'}}>
                                                <FaIcon icon={'times'} /><span className="hideMobile"> {_('Reject')}</span>
                                            </button>
                                        </Tooltip>
                                    </span>
                                }
                                {
                                    this.props.qualityAssurance.defects.length > checked.length &&
                                    <span>
                                        <Tooltip content={_('Not checked all items in this batch yet')} className="target">
                                            <button className="smallButton" onClick={() => this.handleBack()} style={{marginLeft: '0.3em', marginBottom: '0.3em'}}>
                                                <FaIcon icon={'undo'} /><span className="hideMobile"> {_('Go Back')}</span>
                                            </button>
                                        </Tooltip>
                                    </span>
                                }
                            </div>
                        </td>
                    </tr>);
                }

                return <div>
                    {
                        this.state.inspections &&
                        <Inspections
                            data={currentqualityAssuranceItem}
                            inspections={currentqualityAssuranceItem.inspections}
                            defects={qualityAssurance.defects}
                            onClose={() => this.setState({inspections: false})}
                        />
                    }
                    <table id="currentWork" cellPadding={0} cellSpacing={0}>
                        <tbody>
                        {content}
                        </tbody>
                    </table>
                </div>;
            }
        }

    }
)
