import * as C from '../utils/constants';

export default class QualityAssuranceEntity {

    constructor(state = null) {
        this.state = state;
    }


    setState(state) {
        this.state = state;
    }


    getState(clone = false) {
        if(clone) {
            return JSON.parse(JSON.stringify(this.state))
        }
        return this.state;
    }


    validate() {
        let errors = [];

        return errors;
    }


    getCurrentItem() {
        if(this.state) {
            for(let i in this.state.subjects) {
                if(this.state.subjects[i].current) {
                    return this.state.subjects[i];
                }
            }
        }

        return null;
    }


    findItem(serial_number) {
        if(this.state) {
            for(let i in this.state.subjects) {
                if(this.state.subjects[i].serial_number == serial_number) {
                    return this.state.subjects[i];
                }
            }
        }

        return null;
    }


    getStats() {
        let result = {
            total: 0,
            checked: 0,
            notChecked: 0,
            ok: 0,
            nok: 0,
            reworked: 0,
        };

        if(this.state) {
            this.state.subjects.forEach((qualityAssuranceItem) => {
                result.total++;
                switch (qualityAssuranceItem.status) {
                    case C.qaStatus.OK:
                        result.ok++;
                        break;
                    case C.qaStatus.NOT_CHECKED:
                        result.notChecked++;
                        break;
                    case C.qaStatus.NOK:
                        result.nok++;
                        break;
                    case C.qaStatus.REWORKED:
                        result.reworked++;
                        break;
                }
                if(qualityAssuranceItem.status == C.qaStatus.OK) {
                    result.checked++;
                }
            });
        }

        result.checked = result.total - result.notChecked

        return result;
    }


    populateNewState() {
        this.state = JSON.parse(JSON.stringify(this.newState));
        return this;
    }
}
