import React from 'react';
import {sprintf} from 'sprintf-js';
import {_} from '../locales/gettext';
import * as C from "./constants";
import ErrorMessage from "../components/errorMessages";
import FlashMessage from "../components/flashMessage";

export default class ComponentHelpers {

    constructor(component, api = null) {
        this.component = component;
        this.api = api;
    }


    async handleAsyncError(fnc, callback, fullScreen = true) {
        try {
            await fnc();
        } catch (error) {
            this.setErrorMessage(error, callback, fullScreen);
        }
    }


    setErrorMessage(error, callback, fullScreen = true) {
        let submitFunc;
        let invalidSubmitFunc;

        switch(error.code) {
            case C.errors.CHECK_TL:
                submitFunc = (result) => { callback(result); }
                invalidSubmitFunc = (err) => { error.alert = err.message; this.setErrorMessage(error, callback, fullScreen); }
                break;
            default:
                submitFunc = function() {};
                invalidSubmitFunc = function() {};
        }

        this.component.props.flashMessageActions.set(
            <ErrorMessage
                onSubmit={(result) => submitFunc(result)}
                onInvalidSubmit={(error) => invalidSubmitFunc(error)}
                errorCode={error.code}
                errorMessage={error.message}
                alert={error.alert}
                fullScreen={fullScreen}
            />
        );
    }


    async checkTL(auth, jobs, callback) {
        if(jobs.length) {
            const jobCode = jobs.shift();
            try {
                const response = await this.api.__call('get', C.workGroup + '/jobs/' + jobCode + '/operators/' + auth.identity.id, {}, auth.token);
                if(!response.supervisor) {
                    this.setErrorMessage({code: null, message: sprintf(_('User is not TeamLeader for job %s'), jobCode)}, {}, false)
                } else {
                    this.checkTL(auth, jobs, callback);
                }
            } catch (error) {
                this.setErrorMessage(error, null, false);
            }
        } else {
            callback();
        }
    }


    getDefectStateTitle(state) {
        switch(state) {
            case C.qaDefectStatus.ABSENT:
                return _('OK');
            case C.qaDefectStatus.PRESENT:
                return _('NOK');
            case C.qaDefectStatus.REPAIRED:
                return _('Rework');
            default:
                return _('N/A');
        }
    }

    getDefectStateBadge(state) {
        switch(state) {
            case C.qaDefectStatus.ABSENT:
                return '🟢';
            case C.qaDefectStatus.PRESENT:
                return '🔴';
            case C.qaDefectStatus.REPAIRED:
                return '🟡';
            default:
                return null;
        }
    }

    getDefectStateColor(state) {
        switch(state) {
            case C.qaDefectStatus.ABSENT:
                return '🟢';
            case C.qaDefectStatus.PRESENT:
                return '🔴';
            case C.qaDefectStatus.REPAIRED:
                return '🟡';
            default:
                return null;
        }
    }


    getDocumentType(type) {
        if(type.includes('img') || type.includes('image')) {
            return C.documentType.IMAGE;
        } else if(type.includes('video')) {
            return C.documentType.VIDEO
        } else if(type.includes('pdf')) {
            return C.documentType.PDF
        } else if(type.includes('photo_serie')) {
            return C.documentType.PHOTO_SERIE
        }

        return _('N/A');
    }


    getDocumentTypeTitle(type) {
        switch(type) {
            case C.documentType.IMAGE:
                return _('Image');
            case C.documentType.VIDEO:
                return _('Video');
            case C.documentType.PDF:
                return _('PDF');
            case C.documentType.PHOTO_SERIE:
                return _('Photo serie');
        }

        return _('N/A');
    }


    getDocumentDetail(type, data, onDetailClick = null, full = false, onDetailClose = null) {
        switch(type) {
            case C.documentType.IMAGE:
                let src = null;
                if(data.base64) {
                    src = data.base64;
                } else if(data.file && data.file.url) {
                    src = data.file.url;
                }

                if(src) {
                    if(full) {
                        return <FlashMessage
                            closeButton={true}
                            okButton={true}
                            okButtonTitle={_('Close detail')}
                            fullScreen={true}
                            type={C.flashMessages.COMMON}
                            onClose={() => {
                                if(onDetailClose) {
                                    onDetailClose();
                                }
                            }}
                        >
                            <img
                                className="full"
                                src={src}
                            />
                        </FlashMessage>
                    } else {
                        return <img
                            className="thumb"
                            src={src}
                            onClick={() => {
                                if(onDetailClick) {
                                    onDetailClick();
                                }
                            }}
                        />;
                    }
                }
                break;
            case C.documentType.PHOTO_SERIE:
                return data.data.map((item, index) => {
                    if(full !== false && full == index) {
                        return <FlashMessage
                            closeButton={true}
                            okButton={true}
                            okButtonTitle={_('Close detail')}
                            fullScreen={true}
                            type={C.flashMessages.COMMON}
                            onClose={() => {
                                if(onDetailClose) {
                                    onDetailClose();
                                }
                            }}
                        >
                            <img
                                className="full"
                                src={item.base64}
                            />
                        </FlashMessage>;
                    } else {
                        return <img
                            className="thumb"
                            src={item.base64}
                            onClick={() => {
                                if(onDetailClick) {
                                    onDetailClick(index);
                                }
                            }}
                        />;
                    }
                });
        }

        return <React.Fragment />;
    }

}
