const SET = '@unfinishedWorksheets/SET';
const CLEAR = '@unfinishedWorksheets/CLEAR';
const REMOVE = '@unfinishedWorksheets/REMOVE';

const unfinishedWorksheets = (state = [], action) => {
    switch (action.type) {
        case SET:
            return action.items;

        case REMOVE:
            let newItems = [];
            if(Array.isArray(state)) {
                state.forEach((item) => {
                    if(item.id != action.id) {
                        newItems.push(item);
                    }
                });
            }
            return newItems;

        case CLEAR:
            return [];

        default:
            return state
    }
}

export default unfinishedWorksheets

export const set = (items) => ({
    type: SET,
    items,
});

export const remove = (id) => ({
    type: REMOVE,
    id,
});


export const clear = () => ({
    type: CLEAR,
});
