import React from 'react'
import Form from './form'
import Input from './input'
import * as C from '../utils/constants'
import {_} from '../locales/gettext'

export default class Batch extends Form {
  static defaultProps = {
    submitText: _('Continue'),
    title: _('Scan Batch & Quantity'),
    requiredBatch: true,
    requiredName: true,
    requiredPartNumber: '',
  }

  constructor(props) {
    super(props)

    this.state = {
      submitCheck: false,
      batch: false,
      quantity: false,
      partNumber: false,
      name: false,
    }
  }

  componentDidMount() {
    this.refs.partNumber.refs.handScan.openScan(true)
  }

  isBatch(value) {
    let isBatch = false
    C.prefixes.BATCH.forEach((prefix) => {
      if (value.length > 1 && value.startsWith(prefix)) {
        isBatch = true
      }
    })
    return isBatch
  }

  isQuantity(value) {
    let isQuantity = false
    C.prefixes.QUANTITY.forEach((prefix) => {
      if (value.length > 1 && value.startsWith(prefix)) {
        isQuantity = true
      }
    })
    return isQuantity
  }

  isPartNumber(value) {
    let isPartNumber = false
    C.prefixes.PART_NUMBER.forEach((prefix) => {
      if (value.length > 1 && value.startsWith(prefix)) {
        isPartNumber = true
      }
    })
    return isPartNumber
  }

  fillInput(value, input, skipTo = null) {
    this.refs[input].setValue(value)
    let state = {}
    state[input] = true
    this.setState(state)
    if (skipTo) {
      this.refs[skipTo].setValue('')
      setTimeout(() => {
        this.refs[skipTo].refs.handScan.openScan(true)
      }, 100)
    }
  }

  resolveInputs(input) {
    let state = {}
    state[input] = true
    this.setState(state, () => {
      if (this.refs[input].isValid()) {
        if (
          (this.state.batch || !this.props.requiredBatch) &&
          (this.state.name || !this.props.requiredName) &&
          this.state.quantity &&
          this.state.partNumber
        ) {
          this.submit()
        } else {
          if (!this.state.partNumber) {
            this.refs.partNumber.refs.handScan.openScan(true)
          } else if (!this.state.batch && this.props.requiredBatch) {
            this.refs.batch.refs.handScan.openScan(true)
          } else if (!this.state.quantity) {
            this.refs.quantity.refs.handScan.openScan(true)
          } else if (!this.state.name && this.props.requiredName) {
            this.refs.name.refs.handScan.openScan(true)
          }
        }
      } else {
        setTimeout(() => {
          if (this.refs[input]) {
            this.refs[input].refs.handScan.openScan(true)
          }
        }, 1000)
      }
    })
  }

  handleScanBatch() {
    const value = this.refs.batch.getValue().trim()

    if (this.isQuantity(value)) {
      this.fillInput(value, 'quantity', 'batch')
    } else if (this.isPartNumber(value)) {
      this.fillInput(value, 'partNumber', 'batch')
    } else {
      this.resolveInputs('batch')
    }
  }

  handleScanQuantity() {
    const value = this.refs.quantity.getValue().trim()

    if (this.isBatch(value) && this.props.requiredBatch) {
      this.fillInput(value, 'batch', 'quantity')
    } else if (this.isPartNumber(value)) {
      this.fillInput(value, 'partNumber', 'quantity')
    } else {
      this.resolveInputs('quantity')
    }
  }

  handleScanPartNumber() {
    const value = this.refs.partNumber.getValue().trim()

    if (this.isBatch(value) && this.props.requiredBatch) {
      this.fillInput(value, 'batch', 'partNumber')
    } else if (this.isQuantity(value)) {
      this.fillInput(value, 'quantity', 'partNumber')
    } else {
      this.resolveInputs('partNumber')
    }
  }

  handleScanName() {
    const value = this.refs.name.getValue().trim()

    if (this.isBatch(value) && this.props.requiredBatch) {
      this.fillInput(value, 'batch', 'name')
    } else if (this.isPartNumber(value)) {
      this.fillInput(value, 'partNumber', 'name')
    } else if (this.isQuantity(value)) {
      this.fillInput(value, 'quantity', 'name')
    } else {
      this.resolveInputs('name')
    }
  }

  render() {
    let inputs = (
      <div>
        <Input
          ref='partNumber'
          placeholder={_('Part Number')}
          check={this.state.submitCheck || this.state.partNumber}
          requiredPrefix={C.prefixes.PART_NUMBER}
          possibleValues={this.props.requiredPartNumber}
          convertChars={true}
          handScan={true}
          handScanOpen={false}
          onScanSubmit={() => this.handleScanPartNumber()}
        />
        {this.props.requiredBatch && (
          <Input
            ref='batch'
            placeholder={_('Batch Code')}
            check={this.state.submitCheck || this.state.batch}
            required={this.props.requiredBatch}
            requiredPrefix={this.props.requiredBatch ? C.prefixes.BATCH : ''}
            convertChars={true}
            handScan={true}
            handScanOpen={false}
            onScanSubmit={() => this.handleScanBatch()}
          />
        )}
        <Input
          type='number'
          ref='quantity'
          placeholder={_('Quantity')}
          check={this.state.submitCheck || this.state.quantity}
          requiredPrefix={C.prefixes.QUANTITY}
          convertChars={true}
          handScan={true}
          handScanOpen={false}
          onScanSubmit={() => this.handleScanQuantity()}
        />
        {!!this.props.requiredName && (
          <Input
            ref='name'
            placeholder={_('Box Name')}
            check={this.state.submitCheck || this.state.name}
            required={!!this.props.requiredName}
            convertChars={true}
            handScan={true}
            handScanOpen={false}
            onScanSubmit={() => this.handleScanName()}
          />
        )}
      </div>
    )

    return this.getForm(inputs)
  }
}
