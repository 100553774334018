import React from 'react';
import FlashMessage from './flashMessage';
import {_} from '../locales/gettext';
import * as C from '../utils/constants';
import FaIcon from './faIcon';

export default class UpdateMessage extends React.Component {

    render() {
        return <FlashMessage
            fullScreen={true}
            type={C.flashMessages.ALERT}
            closeButton={false}
            okButton={false}
        >
            <h3>{_('New version is available. Click to update.')}</h3><br />
            <button
                onClick={() => {
                    this.props.registration.update();
                    this.props.registration.unregister().then(() => {
                        window.location.reload();
                    });
                }}
            >
                <FaIcon icon={'download'} /> {_('Update')}
            </button>
        </FlashMessage>;
    }

}
