import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'react-tooltip-lite';
import {_} from '../locales/gettext';
import Input from '../forms/input';
import FaIcon from './faIcon';
import classNames from "classnames";
import * as C from '../utils/constants';
import Inspections from "./inspections";
import {connect} from 'react-redux';
import {bindActionCreators} from '../redux/bindActionCreators';
import * as flashMessageActions from '../redux/modules/flashMessage';
import FlashMessage from './flashMessage';


export default connect(
    (state, props) => {
        return {
        }
    },
    (dispatch) => {
        return {
            flashMessageActions: bindActionCreators(flashMessageActions, dispatch),
        }
    }
)(
    class QualityAssuranceItemSelect extends React.Component {
        static propTypes = {
            data: PropTypes.array.isRequired,
            defects: PropTypes.array.isRequired,
            onStart: PropTypes.func.isRequired,
            onAdd: PropTypes.func.isRequired,
            onRemove: PropTypes.func.isRequired,
            onCheck: PropTypes.func.isRequired,
            onPositionFilter: PropTypes.func.isRequired,
            filterValue: PropTypes.object,
            fixedSubjectList: PropTypes.bool
        };


        constructor(props) {
            super(props);

            this.state = {
                error: false,
                serial_number: null,
                position: null,
                selectedQualityAssuranceItems: [],
                inspections: null,
                filterValue: {
                    position: '',
                },
            };

            this.valueTimeout = null;
        }


        componentDidUpdate(prevProps, prevState, snapshot) {
        }


        componentDidMount() {

        }


        handleKeyDown(e) {
            if(e.key == 'Enter' || e.key == 'Tab') {
                this.handleValue(this.refs.code.getValue());
            }
        }


        handleValue(str, start = true) {
            let value;
            let count = 0;
            let lastItem = null;
            let stateItem = null;

            if(str) {
                this.props.data.forEach((item) => {
                    value = str.trim().toLowerCase();
                    if (value && item.serial_number.trim().toLowerCase() == value) {
                        stateItem = item;
                        lastItem = item;
                        count++;
                    }
                });
            }

            if(count == 1 && start) {
                this.handleSelect(lastItem);
            }

            if(!count) {
                this.setState({
                    error: !count,
                    serial_number: str.trim(),
                    selectedQualityAssuranceItems: [],
                });
            } else {
                this.setState({
                    error: !count,
                    serial_number: str.trim(),
                    selectedQualityAssuranceItems: stateItem ? [stateItem] : []
                });
            }
        }


        handleHandScan() {
            setTimeout(() => {
                if(this.refs.code && this.refs.code.refs.handScan) {
                    this.refs.code.refs.handScan.openScan(true);
                }
            }, 1000);
        }


        handleQualityAssuranceItem(item) {
            let selectedQualityAssuranceItems = [];

            if(item) {
                selectedQualityAssuranceItems.push(item);
            }

            this.setState({selectedQualityAssuranceItems: selectedQualityAssuranceItems});
        }


        handleSelect(item = null) {
            if(!item) {
                item = this.state.selectedQualityAssuranceItems[0];
            }
            if(item.status != C.qaStatus.NOT_CHECKED) {
                this.props.flashMessageActions.set(<FlashMessage
                    fullScreen={true}
                    type={C.flashMessages.ALERT}
                    closeButton={true}
                >
                    <h3>{_('Subject already checked')}</h3>
                    <div>{_('Current status:')} <strong>{this.getStatus(item.status)}</strong></div>
                    <div style={{marginTop: '2em'}} className="scanHide">
                        <button onClick={() => {
                            this.props.onStart(item.serial_number);
                            this.props.flashMessageActions.clear();
                        }}>
                            {_('Continue anyway')}
                        </button>
                        <button onClick={() => this.props.flashMessageActions.clear()} style={{marginLeft: '1em'}}>
                            <FaIcon icon={'undo'} /> {_('Go Back')}
                        </button>
                    </div>
                </FlashMessage>);
            } else {
                this.props.onStart(item.serial_number);
            }
        }


        handleAdd(serial_number) {
            this.props.onAdd(serial_number, this.refs.position.getValue());
            setTimeout(() => {
                this.handleValue(serial_number);
                //this.refs.code.setValue(serial_number);
            }, 10);
        }


        handleRemove(serial_number) {
            if(window.confirm(_('Remove Item, are you sure?'))) {
                this.props.onRemove(serial_number);
                setTimeout(() => {
                    this.handleValue(serial_number);
                    this.refs.code.setValue(serial_number);
                }, 10);
            }
        }


        handleCheck(serial_number, checked) {
            if(!checked) {
                if(window.confirm(_('Mark as unchecked, are you sure?'))) {
                    this.props.onCheck(serial_number, checked);
                }
            } else {
                this.props.onCheck(serial_number, checked);
            }
        }


        getStatus(status) {
            switch(status) {
                case C.qaStatus.OK:
                    return _('OK');
                case C.qaStatus.NOK:
                    return _('NOK');
                case C.qaStatus.NOT_CHECKED:
                    return _('Not checked');
                case C.qaStatus.REWORKED:
                    return _('Reworked');
                case C.qaStatus.NOT_FOUND:
                    return _('Not found');
                default:
                    return _('N/A');
            }
        }


        render() {
            let rows = [];
            let selectedRows = [];
            const selectedCount = this.state.selectedQualityAssuranceItems.length;

            const rendRow = (item, key, selected) => <tr key={key}>
                <td>
                    {item.serial_number}
                    {
                      !!item.new &&
                      item.status == C.qaStatus.NOT_CHECKED &&
                      <span
                        onClick={() => this.handleRemove(item.serial_number)}
                        style={{float: 'right', cursor: 'pointer', display: 'inline-block'}}
                      >
                                <Tooltip content={_('Remove item')}>
                                    <FaIcon icon="trash-alt" />
                                </Tooltip>
                            </span>
                    }
                </td>
                <td>
                    {this.getStatus(item.status)}
                </td>
                <td>{item.label}</td>
                <td>
                    <button className={classNames({smallButton: true, disabled: !selected, pointer: true})} onClick={() => this.handleQualityAssuranceItem(item)}>
                        <span style={{width: 'bold', fontSize: '1.5em'}}>{selected ? '✓' : '×'}</span>
                    </button>
                </td>
            </tr>

            let selectedItems = []
            const subjectsToRender = this.props.data.filter((item, key) => {
                let selected = false
                this.state.selectedQualityAssuranceItems.forEach((item2) => {
                    if(item2.serial_number == item.serial_number) {
                        selectedItems.push(item)
                        selected = true
                    }
                });
                item.selected = selected
                return !item.filtered || selected
            });

            // selectedItems.forEach((item, key) => {
            //     rows.push(rendRow(item, `selected-${key}`, true));
            // });
            selectedItems.forEach((item, key) => {
                selectedRows.push(<div style={{marginBottom:'10px'}} key={key}>
                    <strong>{item.serial_number}</strong>
                    {item.label ? ` (${item.label})` : ''}
                </div>);
            });

            subjectsToRender.forEach((item, key) => {
                rows.push(rendRow(item, key, item.selected));
            });

            if(!rows.length) {
                rows.push(<tr>
                    <td colSpan={5} className="empty">{_('no subjects found')}</td>
                </tr>)
            }

            if(this.state.inspections) {
                return <Inspections
                    data={this.state.inspections}
                    inspections={this.state.inspections.inspections}
                    defects={this.props.defects}
                    onClose={() => this.setState({inspections: null})}
                />;
            }

            return <div>
                {/*<h3 className="scanHide">*/}
                {/*    {_('Available EOL Subjects')}:*/}
                {/*</h3>*/}
                <div className="formInput">
                    <Input
                        ref="code"
                        required={true}
                        onChange={(value) => {
                            if(this.valueTimeout) {
                                clearTimeout(this.valueTimeout);
                            }

                            this.valueTimeout = setTimeout(() => {
                                this.handleValue(value, false);
                            }, 300);
                        }}
                        onKeyDown={(e) => this.handleKeyDown(e)}
                        convertChars={true}
                        handScan={true}
                        handScanOpen={true}
                        placeholder={_('Subject Code')}
                        onScanSubmit={() => this.handleHandScan()}
                        error={this.state.error ? (_('Code not Found') + '.' + (this.props.fixedSubjectList ? ' ' + _('Adding new subjects is disabled.') : '')) : null}
                    />
                </div>
                {
                  this.state.error && this.state.serial_number && !this.props.fixedSubjectList &&
                  <div className="formInput">
                      <Input
                        ref="position"
                        required={false}
                        placeholder={_('Item Position')}
                        scan={false}
                      />
                      <button className={classNames()} style={{marginBottom: '1.5em'}} onClick={() => { this.handleAdd(this.state.serial_number); }}>
                          {_('Add Subject') + ': ' + this.state.serial_number}
                      </button>
                  </div>
                }
                <div style={{minHeight: '40px'}}>
                {
                  selectedItems.length >0 ? <div>{selectedRows}</div> : ''
                }
                </div>
                <button
                    className={classNames({disabled: !selectedCount})}
                    onClick={() => { this.handleSelect(); }}
                    disabled={!selectedCount}
                >
                    {_('Start inspection')}
                </button>
                <table
                    className="jobsTable scanHide"
                    border="1"
                    cellPadding="10"
                    cellSpacing="0"
                    style={{marginTop: '3em'}}
                >
                    <tbody>
                    <tr key="heading" className="headRow">
                        <th>{_('Code')}</th>
                        <th>{_('Status')}</th>
                        <th>
                            {_('Position')}
                            <div style={{display: 'inline-block', marginLeft: '0.5em'}}>
                                <Input
                                    defaultValue={this.props.filterValue.position}
                                    ref="positionSearch"
                                    required={false}
                                    placeholder={_('search')}
                                    scan={false}
                                    onChange={(value) => this.props.onPositionFilter(value)}
                                />
                            </div>
                        </th>
                        <th>{_('Select')}</th>
                    </tr>
                    {rows}
                    </tbody>
                </table>
            </div>;
        }

    }
)

