module.exports.VERSION = '1.1.5'
module.exports.API_VERSION = '1'

module.exports.defaultLang = 'en'

module.exports.scope = 'collector'

module.exports.workGroup = 'operator-app' // TODO collector ?

module.exports.qaGroup = 'qa'

module.exports.languages = ['en', 'cs', 'sk', 'hu']

module.exports.routes = {
  HOMEPAGE: process.env.REACT_APP_PUBLIC_URL + 'home',
  LOGIN: process.env.REACT_APP_PUBLIC_URL + 'login',
  WORK: process.env.REACT_APP_PUBLIC_URL + 'work',
  TEST_SCAN: process.env.REACT_APP_PUBLIC_URL + 'test-scan',
  UNFINISHED: process.env.REACT_APP_PUBLIC_URL + 'unfinished',
  QUALITY_ASSURANCE: process.env.REACT_APP_PUBLIC_URL + 'quality-assurance',
  AUDIT: process.env.REACT_APP_PUBLIC_URL + 'audit',
}

module.exports.flashMessages = {
  INFO: 1,
  ALERT: 2,
  ERROR: 3,
  COMMON: 4,
}

module.exports.timeouts = {
  API_LOADING: 0, // timeout pro zobrazeni loaderu v ms
  REFRESH_TOKEN: 900, // threshold pro refresh expirace tokenu v s
  AUTH_CHECK: 60000, // timeout pro check prihlaseni v ms
}

module.exports.errors = {
  BAD_RESPONSE: 1,
  BAD_LOGIN: 1000,
  CHECK_TL: 1001,
  JOB_CLOSED: 1002,
  INSTRUCTION_NOT_FOUND: 1003,
  INSTRUCTION_NOT_APPROVED: 1004,
  PART_NUMBER_NOT_FOUND: 1005,
  INVALID_TRAINER_ID: 1006,
}

module.exports.reworkable = {
  REWORKABLE: 1,
  NOT_REWORKABLE: 2,
}

module.exports.roles = {
  SUPERADMIN: 1,
  ADMIN: 2,
  TEAMLEADER: 6,
  SENIOR_OPERATOR: 7,
  OPERATOR: 8,
}

module.exports.prefixes = {
  PART_NUMBER: ['P'],
  BATCH: ['S', '5K', 'V'],
  QUANTITY: ['Q', '(Q)'],
}

module.exports.qaStatus = {
  NOT_CHECKED: 'not-checked',
  OK: 'ok',
  NOK: 'nok',
  REWORKED: 'rework',
  NOT_FOUND: 'not-found',
}

module.exports.qaDefectStatus = {
  ABSENT: 'absent',
  PRESENT: 'present',
  REPAIRED: 'repaired',
}

module.exports.documentType = {
  IMAGE: 1,
  VIDEO: 2,
  PDF: 3,
  PHOTO_SERIE: 4,
}
