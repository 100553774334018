import React from 'react'
import PropTypes from 'prop-types'
import CodeReader from '../components/codeReader'
import HandScan from '../components/handScan'
import Input from './input'
import {_} from '../locales/gettext'

export default class UserInput extends React.Component {
  static propTypes = {
    check: PropTypes.bool,
    onScanSubmit: PropTypes.func,
  }

  static defaultProps = {
    check: false,
  }

  isValid() {
    return this.refs.username.getValue() !== '' && this.refs.password.getValue() !== ''
  }

  getValue() {
    let value = {}

    for (let key in this.refs) {
      if (typeof this.refs[key].getValue != 'undefined') {
        value[key] = this.refs[key].getValue()
      }
    }

    return value
  }

  constructor(props) {
    super(props)

    this.state = {
      submitCheck: false,
    }
  }

  submit() {
    this.setState({
      submitCheck: true,
    })
  }

  async handleScan(data) {
    data = JSON.parse(data)
    if (data) {
      if (data.token && data.full_name) {
        await this.refs.username.setValue(data.full_name)
        await this.refs.token.setValue(data.token)
        await this.refs.password.setValue('*****')
      } else {
        if (data.username) {
          await this.refs.username.setValue(data.username)
        }
        if (data.password) {
          await this.refs.password.setValue(data.password)
        }
      }
      if (this.props.onScanSubmit) {
        this.props.onScanSubmit()
      }
    }
  }

  isEmpty() {
    return (
      this.props.required &&
      this.props.check &&
      (this.refs.username.getValue() === '' || this.refs.password.getValue() === '')
    )
  }

  render() {
    return (
      <div>
        <CodeReader
          tooltip={_('Scan User Card by Camera')}
          onScan={(data) => this.handleScan(data)}
          allowBarCode={false}
          defaultType={'QR'}
        />
        <HandScan
          ref='handScan'
          tooltip={_('Scan User Card by Scanner')}
          onScan={(data) => this.handleScan(data)}
          parseJSON={true}
          convertChars={true}
          open={true}
          placeholder={_('User Card')}
          keyboardTooltip={_('Type Username & Password')}
        />
        <Input
          check={this.props.check}
          ref='username'
          placeholder={_('Login_name')}
          scan={false}
        />
        <Input
          check={this.props.check}
          ref='password'
          placeholder={_('Password')}
          type='password'
          scan={false}
        />
        <Input
          check={false}
          ref='token'
          type='hidden'
          scan={false}
        />
      </div>
    )
  }
}
